/**
 * Kopiert den Inhalt eines bestimmten Containers in die Zwischenablage.
 * @param {Array} containers - Array aller Container.
 * @param {number} index - Index des zu kopierenden Containers.
 * @param {Function} setContainers - Funktion zum Aktualisieren des Container-Zustands.
 */
export const copyToClipboard = (containers, index, setContainers) => {
  const container = containers[index];
  let textToCopy = `${container.zielgruppe} ${container.freitext1 || ""} ${
    container.qualifikationsebene
  }`;

  // Überprüfen von removeHandlungsebene
  if (!container.removeHandlungsebene) {
    textToCopy += ` ${container.freitext2 || ""}, indem ${
      container.freitext3 || ""
    } ${container.handlungsebene}`;
    if (container.freitext4) {
      textToCopy += ` ${container.freitext4}`;
    }
  } else {
    textToCopy += ` ${container.freitext2 || ""}`;
  }

  // Hinzufügen des Verbindungsworts nur wenn es nicht "keine" ist
  if (container.nutzenformulierung !== "keine") {
    textToCopy += `${container.nutzenformulierung} ${
      container.freitext5 || ""
    }`;
  }

  // Bereinigung des Textes
  textToCopy = textToCopy.replace(/\s{2,}/g, " ").trim() + ".";

  // Kopieren des Textes in die Zwischenablage
  navigator.clipboard.writeText(textToCopy);
  setContainers((prevContainers) =>
    prevContainers.map((c, i) => (i === index ? { ...c, isCopied: true } : c))
  );
  setTimeout(() => {
    setContainers((prevContainers) =>
      prevContainers.map((c, i) =>
        i === index ? { ...c, isCopied: false } : c
      )
    );
  }, 2000);
};

/**
 * Kopiert den Inhalt aller Container in die Zwischenablage.
 * @param {Array} containers - Array aller Container.
 * @param {Function} setIsAllCopied - Funktion zum Setzen des Kopierstatus.
 */
export const copyAllToClipboard = (containers, setIsAllCopied) => {
  let allTextToCopy = containers
    .map((container) => {
      let textToCopy = `${container.zielgruppe} ${container.freitext1 || ""} ${
        container.qualifikationsebene
      }`;

      if (!container.removeHandlungsebene) {
        textToCopy += ` ${container.freitext2 || ""}, indem ${
          container.freitext3 || ""
        } ${container.handlungsebene}`;
        if (container.freitext4) {
          textToCopy += ` ${container.freitext4}`;
        }
      } else {
        textToCopy += ` ${container.freitext2 || ""}`;
      }

      if (container.nutzenformulierung !== "keine") {
        textToCopy += `${container.nutzenformulierung} ${
          container.freitext5 || ""
        }`;
      }

      return textToCopy.replace(/\s{2,}/g, " ").trim() + ".";
    })
    .join("\n");

  navigator.clipboard.writeText(allTextToCopy);
  setIsAllCopied(true);
  setTimeout(() => {
    setIsAllCopied(false);
  }, 2000);
};

/**
 * Löscht einen Container aus der Liste und aktualisiert den Local Storage.
 * @param {Array} containers - Array aller Container.
 * @param {number} index - Index des zu löschenden Containers.
 * @param {Function} updateContainers - Funktion zum Aktualisieren des Container-Zustands.
 * @param {Function} [onDeleteCallback] -Aufruf nach Löschen des Containers(für Anpassung der Breite der Textareas)
 */
export const deleteContainer = (
  containers,
  index,
  updateContainers,
  onDeleteCallback
) => {
  const updatedContainers = containers.filter((_, i) => i !== index);
  updateContainers(updatedContainers);

  if (onDeleteCallback) {
    setTimeout(onDeleteCallback, 0);
  }
};
