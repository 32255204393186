import React from "react";

function LernzielBeispiele() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">
        Beispiele für gut formulierte Feinlernziele
      </h1>
      <p className="wiki-text-aufbau">
        <span className="wiki-qualifikationsebene">
          <span className="wiki-zielgruppe">
            <strong>
              Die Lernenden<span className="wiki-klammern">(Zielgruppe)</span>
            </strong>
          </span>
          <span className="wiki-kompetenzverb">
            <strong>
              {" "}
              wenden<span className="wiki-klammern">(Kompetenzverb)</span>
            </strong>
          </span>{" "}
          ihre Kenntnisse zum Thema Schneidtechniken
          <span className="wiki-kompetenzverb">
            <strong> an</strong>
          </span>
        </span>{" "}
        <span className="wiki-trennung">
          <strong> , indem sie</strong>
        </span>{" "}
        <span className="wiki-handlungsebene">
          in Einzelarbeit<span className="wiki-klammern">(Situation)</span>,
          beim Gemüseschneiden<span className="wiki-klammern">(Objekt)</span>{" "}
          die Schnitte korrekt unter zu Hilfenahme des Lehrbuches
          <span className="wiki-klammern">(Hilfsmittel)</span>{" "}
          <span className="wiki-tätigkeitsverb">
            <strong>
              {" "}
              ausführen<span className="wiki-klammern">(Tätigkeitsverb)</span>
            </strong>
          </span>
        </span>{" "}
        <span className="wiki-trennung">
          <strong> , damit </strong>
        </span>{" "}
        <span className="wiki-nutzenformulierung">
          das Verletzungsrisiko beim Schneiden minimiert wird.
        </span>
        <div className="wiki-legende">
          <span className="wiki-qualifikationsebene">
            &nbsp;Qualifikationsebene&nbsp;
          </span>
          <span className="wiki-handlungsebene">
            &nbsp;Handlungsebene&nbsp;
          </span>
          <span className="wiki-nutzenformulierung">
            &nbsp;Nutzenformulierung&nbsp;
          </span>
        </div>
        <p className="text-quelle">
          (David Root 2024 Vgl. Lerche, 2022, S. 81; Meyerhoff & Brühl, 2017, S.
          17-18)
        </p>
      </p>

      <h1 className="wiki-titel">Wirtschaft Technik Haushalt/Soziales</h1>

      <h2 className="wiki-untertitel">kognitiv</h2>
      <p className="wiki-text">
        <ol type="I" className="wiki-list">
          <li>
            Die Schüler:innen gewinnen einen Einblick in die Rechts und
            Geschäftsfähigkeit, indem sie die §§ 1, 104, 110, 113, 516 des BGB
            lesen und die wichtigsten Punkte daraus zusammenstellen.
          </li>
          <br></br>
          <li>
            Die Schüler:innen lernen grundlegende Merkmale von Unternehmen
            kennen, indem sie eine Betriebserkun-dung vor Ort vornehmen
          </li>
          <br></br>
          <li>
            Die Schüler:innen sind in der Lage, ihre ernährungsphysiologischen
            Kenntnisse bei der Lebensmittelauswahl zu übertragen, indem sie die
            gegebenen Lebensmittel aufgrund ihrer Nährstoffe klassifizieren.
          </li>
          <br></br>
          <li value="5">
            Die Schüler:innen können den Preis eines Produkts kalkulieren, indem
            sie Kostenarten, Steuern und Gewinn berechnen und den Markt
            berücksichtigen.
          </li>
          <br></br>
          <li value="7">
            Die Schüler:innen sind in der Lage, eine vielseitige und ausgewogen
            Mahlzeit zu gestalten, indem sie eine Mahlzeit unter
            Berücksichtigung lebensmittelkundlicher Grundlagen planen, die
            Planung unter Verwendung der Grundtechniken der
            Nahrungszubereitungen umsetzen und das Ergebnis bewerten.
          </li>
          <p className="text-quelle">(Koerber & Fischer, 2019, S. 3-5)</p>
        </ol>
      </p>

      <h2 className="wiki-untertitel">affektiv</h2>
      <p className="wiki-text">
        <ol type="I" className="wiki-list">
          <li value="5">
            Die Schüler:innen beurteilen die Bedeutsamkeit von Normalisierten
            Datenbanken, indem sie große Datenmengen untersuchen.
          </li>
          <br></br>
          <li value="5">
            Die Schüler:innen begründen, dass das KOAM-Modell ein zentrales
            informatisches Konzept ist, indem sie die vier Bestandteile (Klasse,
            Objekt, Attribut und Methode) in eigenen Worten erklären und auf
            Beispiele übertragen.
          </li>
          <br></br>
          <li value="6">
            Die Schüler:innen beurteilen ihre eigenen Ernährungsgewohnheiten,
            indem sie diese analysieren, mit Empfehlungen vergleichen und daraus
            Schlüsse für ihr künftiges Essverhalten ableiten.
          </li>
          <br></br>
          <li value="7">
            Die Schüler und Schülerinnen sind in der Lage, eine vielseitige und
            ausgewogen Mahlzeit zu gestalten, indem sie eine Mahlzeit unter
            Berücksichtigung lebensmittelkundlicher Grundlagen planen, die
            Planung unter Verwendung der Grundtechniken der
            Nahrungszubereitungen umsetzen und das Ergebnis bewerten.
          </li>
          <p className="text-quelle">(Koerber & Fischer, 2019, S. 4-5)</p>
        </ol>
      </p>

      <h2 className="wiki-untertitel">psychomotorisch</h2>
      <p className="wiki-text">
        <ol type="I" className="wiki-list">
          <li value="4">
            Die Schüler:innen beherrschen die ordnungsgemäße Einrichtung des
            Arbeitsplatzes in der Küche, indem Sie vor Beginn der Arbeit die
            vorhandenen Arbeitsgeräte mit Hilfe der Liste prüfen und am Ende
            alles wieder aufräumen.“
          </li>
          <br></br>
          <li value="7">
            Die Schüler und Schülerinnen sind in der Lage, eine vielseitige und
            ausgewogen Mahlzeit zu gestalten, indem sie eine Mahlzeit unter
            Berücksichtigung lebensmittelkundlicher Grundlagen planen, die
            Planung unter Verwendung der Grundtechniken der
            Nahrungszubereitungen umsetzen und das Ergebnis bewerten.
          </li>
          <p className="text-quelle">(Koerber & Fischer, 2019, S. 4-5)</p>
        </ol>
      </p>

      <h1 className="wiki-titel">Informatik</h1>

      <h2 className="wiki-untertitel">kognitiv</h2>
      <p className="wiki-text">
        <ol type="I" className="wiki-list">
          <li>
            Die Schüler:innen kennen die Speicherarten ab 1950, indem sie diesen
            Bildern und Jahreszahlen zuordnen.
          </li>
          <br></br>
          <li value="5">
            Die Schüler:innen beurteilen die Bedeutsamkeit von Normalisierten
            Datenbanken, indem sie große Datenmengen untersuchen.
          </li>
          <br></br>
          <li value="5">
            Die Schüler:innen begründen, dass das KOAM-Modell ein zentrales
            informatisches Konzept ist, indem sie die vier Bestandteile (Klasse,
            Objekt, Attribut und Methode) in eigenen Worten erklären und auf
            Beispiele übertragen.
          </li>
        </ol>
      </p>
      <h2 className="wiki-literatur">Literatur</h2>
      <p className="wiki-literatur-text">
        Koerber, R., & Fischer, K. (2019). Handreichung zu
        Lernzielformulierungen im Fach WTH/S.
        <br></br>
        <br></br>
        Lerche, T. (2022). Leistungsbeurteilung an Schulen. Lehrstuhl für
        Schulpädagogik, Ludwig-Maximilians-Universität München.
        <br></br>
        <br></br>
        Meyerhoff, J., & Brühl, C. (2017). Fachwissen lebendig vermitteln: Das
        Methodenhandbuch für Trainer und Dozenten (4., überarbeitete Auflage).
        Springer Gabler.
      </p>
    </div>
  );
}

export default LernzielBeispiele;
