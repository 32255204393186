import React from "react";

function Lernziele() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Lernziele</h1>

      <h2 className="wiki-untertitel">Was sind Lernziele?</h2>
      <p className="wiki-text">
        Lernziele Beschreiben den Erwerb von Kenntnissen, Fähigkeiten,
        Fertigkeiten und sogar Einstellungen - also den Kompetenzen. Dabei
        können fachinhaltliche und überfachliche Kompetenzen beschreiben werden.
        <p className="text-quelle">(Messner et al., 2006, S. 232)</p>
      </p>
      <p className="wiki-text">
        Eine andere Herangehensweise ist es, Lernziele als eine Fähigkeit zu
        betrachten, die, wenn der Lernende sie besitzt, als Leistung beobachtet
        werden kann.
        <br></br>
        <br></br>
        Die Lehrperson soll sich vor dem erstellen des Unterrichts, selbst die
        Frage stellen: Was werden die Lernenden danach tun können, was sie
        vorher nicht tun konnten? Oder auch anders ausgedrückt - Wie wird sich
        der Lernende verändern?
        <p className="text-quelle">(Gagne et al., 2005, S. 133)</p>
      </p>

      <h2 className="wiki-untertitel">Was sind Kompetenzen?</h2>
      <p className="wiki-text">
        Kompetenzen sind ein psychologisch-sozialwissenschaftliches Konstrukt,
        welches die Selbstorganisationsdisposition eines Individuums
        widerspiegelt. Sie beinhalten die Fähigkeit zur Integration von Wissen,
        Fähigkeiten, Fertigkeiten, Motivationen, Einstellungen und Werten und
        ermöglichen damit ein eigenständiges und erfolgreiches handeln in
        variablen und komplexen Situationen
        <p className="text-quelle">(Weinert, 2014, S. 27-28)</p>
      </p>
      <img
        src={`/assets/Kompetenzen.svg`}
        alt="Kompetenzen"
        className="wiki-image"
      />
      <p className="img-quelle">
        Kompetenzen (David Root 2024 Vgl. Kultusministerkonferenz, 2007, S.
        10-11; Weinert, 2014, S. 27-8)
      </p>
      <p className="wiki-text">
        Die Fähigkeit, diese Kompetenzen in konkreten Handlungssituationen
        erfolgreich anzuwenden, nennt man <strong>Handlungskompetenz</strong>.
        Diese umfasst dabei die Integration verschiedener Kompetenzbereiche:
        <br></br>
        <br></br>
        <strong>Fachkompetenz:</strong> die Fähigkeit, Aufgaben und Probleme
        zielgerichtet und sachgerecht auf Basis von Fachwissen zu lösen.
        <br></br>
        <br></br>
        <strong>Humankompetenz:</strong> die persönliche Fähigkeit, sich
        selbstständig zu entwickeln, eigene Stärken zu entfalten und Lebensziele
        zu verfolgen, einschließlich der Fähigkeit zu Selbstvertrauen und
        Verantwortung.
        <br></br>
        <br></br>
        <strong>Sozialkompetenz:</strong> die Fähigkeit, soziale Beziehungen zu
        gestalten und Verantwortung sowie Solidarität zu zeigen.
        <p className="text-quelle">(Kultusministerkonferenz, 2007, S. 10-11)</p>
      </p>

      <p className="wiki-text">
        Alle drei Kompetenzen beinhalten dabei:
        <br></br>
        <br></br>
        <strong>
          Methodenkompetenz
          <br></br>
          &#8658;
        </strong>{" "}
        zielgerichtetes Vorgehen
        <br></br>
        <br></br>
        <strong>
          Kommunikative Kompetenz
          <br></br>
          &#8658;
        </strong>{" "}
        Verständnis und Gestaltung von Kommunikation
        <br></br>
        <br></br>
        <strong>
          Lernkompetenz
          <br></br>
          &#8658;
        </strong>{" "}
        selbstständiges und gemeinsames Lernen
        <p className="text-quelle">(Kultusministerkonferenz, 2007, S. 11)</p>
      </p>

      <h2 className="wiki-untertitel">Abstraktionsniveau und Zielhierarchie</h2>
      <p className="wiki-text">
        <strong>Richtziele:</strong> werden von zentralen Instanzen wie der
        Bildungspolitik vorgegeben. Diese Erziehungs- und Lehrziele werden oft
        in Form von Lehrplänen veröffentlicht und spiegeln grundlegende
        gesellschaftliche und weltanschauliche Werte wider.
        <br></br>
        <br></br>
        <strong>Grobziele:</strong> sind bereits konkreter formuliert. Auf
        dieser Zielebene befinden sich die Schularten, einzelne Schulen mit
        ihren spezifischen Schulprofilen sowie die Lehrpläne der Fächer und
        deren Lerngebiete.
        <br></br>
        <br></br>
        <strong>Feinziele:</strong> beschreiben sehr genau, was Lernende
        innerhalb eines bestimmten Zeitraums unter bestimmten Bedingungen
        leisten können sollen.
        <p className="text-quelle">(Becker, 2008, S. 143-144)</p>
      </p>
      <img
        src={`/assets/Zielhierarchie.svg`}
        alt="Abstraktionsgrad und Zielebenen"
        className="wiki-image"
      />
      <p className="img-quelle">
        Abstraktionsgrad und Zielebenen (David Root 2024 Vgl. Becker, 2008, S.
        144; Möller, 1976, S. 72-77)
      </p>

      <h2 className="wiki-literatur">Literatur</h2>
      <p className="wiki-literatur-text">
        Becker, G. E. (2008). Unterricht durchführen (9., vollst. überarb.
        Aufl). Beltz.
        <br></br>
        <br></br>
        Gagne, R. M., Wager, W. W., Golas, K. C., Keller, J. M., & Russell, J.
        D. (2005). Principles of Instructional Design, 5th Edition. Performance
        Improvement, 44(2), 44-46.
        <br></br>
        <br></br>
        Kultusministerkonferenz. (2007). Handreichung für die Erarbeitung von
        Rahmenlehrplänen der Kultusministerkonferenz für den berufsbezogenen
        Unterricht in der Berufsschule und ihre Abstimmung mit
        Ausbildungsordnungen des Bundes für anerkannte Ausbildungsberufe.
        Sekretariat der Kultusministerkonferenz. http://www.kmk.org
        <br></br>
        <br></br>
        Messner, H., Criblez, L., Künzli, R., Gautschi, P., & Hirt Monico, P.
        (Hrsg.). (2006). Lehrpläne und Bildungsstandards: Was Schülerinnen und
        Schüler lernen sollen; Festschrift zum 65. Geburtstag von Prof. Dr.
        Rudolf Künzli (1. Aufl). hep-Verl.
        <br></br>
        <br></br>
        Möller, C. (1976). Technik der Lernplanung: Methoden und Probleme der
        Lernzielerstellung (5. Aufl., (26.-30. Tsd.)). Beltz.
        <br></br>
        <br></br>
        Weinert, F. E. (Hrsg.). (2014). Leistungsmessungen in Schulen (3.,
        aktualisierte Aufl). Beltz.
        <br></br>
        <br></br>
      </p>
    </div>
  );
}

export default Lernziele;
