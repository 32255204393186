import React, { useState, useEffect, useCallback } from "react";
import {
  FaCopy,
  FaCheck,
  FaPlus,
  FaTrash,
  FaExclamationTriangle,
} from "react-icons/fa";
import { GrCopy } from "react-icons/gr";
import ZielgruppenDropdown from "./zielgruppe.tsx";
import zielgruppeData from "../config/zielgruppe.json";
import NutzenformulierungDropdown from "./nutzenformulierung.tsx";
import { useProjectContext } from "./laufendesProjekt.tsx";
import {
  getQualifikationsebenen,
  getHandlungsebenen,
  saveCustomTaxonomyData,
  getCustomTaxonomyData,
} from "../utils/jsonSelector.ts";
import CustomDropdown from "../utils/CustomDropdown.tsx";
import {
  copyToClipboard,
  copyAllToClipboard,
  deleteContainer,
} from "../utils/buttons.tsx";
import { determineCategory } from "../utils/jsonSelector.ts";

/**
 * Props-Interface für die LernzielTemplate-Komponente
 */
interface LernzielTemplateProps {
  jsonData: any;
  isDropdownActive: boolean;
}

/**
 * Hauptkomponente für das LernzielTemplate
 * @param {LernzielTemplateProps} props - Die Props für die Komponente
 */
const LernzielTemplate: React.FC<LernzielTemplateProps> = ({
  jsonData,
  isDropdownActive,
}) => {
  const [error, setError] = useState<string | null>(null);
  const { laufendesProjekt, setProjektState } = useProjectContext();
  const [zielgruppen, setZielgruppen] = useState(() => {
    const savedZielgruppen = localStorage.getItem("zielgruppen");
    return savedZielgruppen
      ? JSON.parse(savedZielgruppen)
      : zielgruppeData.zielgruppe;
  });

  /**
   * Erstellt einen leeren Container für ein neues Lernziel
   * @param {string[]} currentZielgruppen - Aktuelle Zielgruppen
   * @returns {Object} Ein leerer Container für ein Lernziel
   */
  const createEmptyContainer = useCallback(
    (currentZielgruppen: string[]) => {
      const savedState = localStorage.getItem(laufendesProjekt);
      const parsedState = savedState ? JSON.parse(savedState) : {};
      const defaultNutzenformulierung = jsonData.metadaten.nutzenformulierung
        ? ", damit"
        : "keine";

      return {
        zielgruppe: currentZielgruppen[0] || "",
        qualifikationsebene: "",
        handlungsebene: "",
        nutzenformulierung:
          parsedState.nutzenformulierung || defaultNutzenformulierung,
        freitext1: "",
        freitext2: "",
        freitext3: "",
        freitext4: "",
        freitext5: "",
        removeHandlungsebene:
          jsonData.metadaten.handlungsebene_entfernen || false,
      };
    },
    [laufendesProjekt, jsonData.metadaten]
  );

  const [containers, setContainers] = useState(() => {
    const savedState = localStorage.getItem(laufendesProjekt);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return parsedState.containers || [createEmptyContainer(zielgruppen)];
    }
    return [createEmptyContainer(zielgruppen)];
  });

  const [qualifikationsebenen, setQualifikationsebenen] = useState<
    { category: string; items: string[] }[] | string[]
  >([]);
  const [selectedQualifikationsebene, setSelectedQualifikationsebene] =
    useState<string>("");
  const [handlungsebenen, setHandlungsebenen] = useState<string[][]>(() => {
    return containers.map(() => []);
  });

  const loadAllHandlungsebenen = useCallback(async () => {
    const newHandlungsebenen = await Promise.all(
      containers.map((container) =>
        getHandlungsebenen(laufendesProjekt, container.qualifikationsebene)
      )
    );
    setHandlungsebenen(newHandlungsebenen);
  }, [containers, laufendesProjekt]);

  useEffect(() => {
    loadAllHandlungsebenen();
  }, [loadAllHandlungsebenen]);
  const [currentHandlungsebenen, setCurrentHandlungsebenen] = useState<
    string[]
  >([]);
  const [isAllCopied, setIsAllCopied] = useState(false);

  const [taxonomyCategory, setTaxonomyCategory] = useState<string>("unknown");
  useEffect(() => {
    async function fetchCategory() {
      try {
        const category = await determineCategory(laufendesProjekt);
        setTaxonomyCategory(category);
      } catch (error) {
        console.error("Fehler beim Bestimmen der Kategorie:", error);
        setTaxonomyCategory("unknown");
      }
    }
    fetchCategory();
  }, [laufendesProjekt]);

  useEffect(() => {
    const savedState = localStorage.getItem(laufendesProjekt);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setContainers(
        parsedState.containers || [createEmptyContainer(zielgruppen)]
      );
    } else {
      setContainers([createEmptyContainer(zielgruppen)]);
    }
  }, [laufendesProjekt, jsonData.metadaten, zielgruppen, createEmptyContainer]);

  /**
   * Fügt einen neuen Container (Lernziel) hinzu
   */
  const handleAddContainer = useCallback(() => {
    setContainers((prevContainers) => {
      const newContainers = [
        ...prevContainers,
        createEmptyContainer(zielgruppen),
      ];
      setTimeout(adjustAllTextareas, 0);
      return newContainers;
    });
  }, [createEmptyContainer, zielgruppen]);

  /**
   * Speichert die Container (Lernziele) im localStorage
   * @param {any[]} newContainers - Die zu speichernden Container
   */
  const saveContainers = useCallback(
    (newContainers) => {
      const currentState = localStorage.getItem(laufendesProjekt);
      const parsedState = currentState ? JSON.parse(currentState) : {};
      const updatedState = {
        ...parsedState,
        containers: newContainers,
      };
      localStorage.setItem(laufendesProjekt, JSON.stringify(updatedState));
      setProjektState(laufendesProjekt, updatedState);
    },
    [laufendesProjekt, setProjektState]
  );

  /**
   * Aktualisiert die Container (Lernziele) und speichert die Änderungen im local Storage
   * @param {any[]} newContainers - Die aktualisierten Container
   */
  const updateContainers = useCallback(
    (newContainers) => {
      setContainers(newContainers);
      saveContainers(newContainers);
    },
    [saveContainers]
  );

  /**
   * Ändert den Status der RemoveHandlungsebene
   * @param {number} index - Index des Containers
   * @param {boolean} checked - Neuer Status der Checkbox
   */
  const handleRemoveHandlungsebeneChange = (
    index: number,
    checked: boolean
  ) => {
    const updatedContainers = containers.map((container, i) => {
      if (i === index) {
        return {
          ...container,
          removeHandlungsebene: !checked,
        };
      }
      return container;
    });
    updateContainers(updatedContainers);
    setTimeout(adjustAllTextareas, 0);
  };

  /**
   * Lädt die Qualifikationsebenen
   */
  const loadQualifikationsebenen = async () => {
    const result = await getQualifikationsebenen(laufendesProjekt);
    if ("error" in result) {
      setError(result.error);
      setQualifikationsebenen([]);
    } else {
      setError(null);
      setQualifikationsebenen(result);
      if (Array.isArray(result) && result.length > 0) {
        if (typeof result[0] === "string") {
          setSelectedQualifikationsebene(result[0]);
        } else if (result[0].items && result[0].items.length > 0) {
          setSelectedQualifikationsebene(result[0].items[0]);
        }
      }
    }
  };

  useEffect(() => {
    loadQualifikationsebenen();
  }, [laufendesProjekt]);

  /**
   * Ändert die Nutzenformulierung eines Containers
   * @param {number} index - Index des Containers
   * @param {string} value - Neue Nutzenformulierung
   */
  const handleNutzenformulierungChange = (index: number, value: string) => {
    updateContainers(
      containers.map((container, i) =>
        i === index ? { ...container, nutzenformulierung: value } : container
      )
    );
    setTimeout(adjustAllTextareas, 0);
  };

  useEffect(() => {
    const loadHandlungsebenen = async () => {
      if (selectedQualifikationsebene) {
        const newHandlungsebenen = await getHandlungsebenen(
          laufendesProjekt,
          selectedQualifikationsebene
        );
        setHandlungsebenen((prevHandlungsebenen) =>
          prevHandlungsebenen.map(() => newHandlungsebenen)
        );
      }
    };
    loadHandlungsebenen();
  }, [laufendesProjekt, selectedQualifikationsebene]);

  /**
   * Ändert die Zielgruppe eines Containers
   * @param {number} index - Index des Containers
   * @param {string} value - Neue Zielgruppe
   */
  const handleZielgruppeChange = (index: number, value: string) => {
    updateContainers(
      containers.map((container, i) =>
        i === index ? { ...container, zielgruppe: value } : container
      )
    );
  };

  /**
   * Ändert die Qualifikationsebene eines Containers und lädt die entsprechenden Handlungsebenen
   * @param {number} index - Index des Containers
   * @param {string} value - Neue Qualifikationsebene
   */
  const handleQualifikationsebeneChange = useCallback(
    async (index: number, value: string) => {
      const newContainers = containers.map((container, i) =>
        i === index
          ? { ...container, qualifikationsebene: value, handlungsebene: "" }
          : container
      );
      updateContainers(newContainers);

      const newHandlungsebenen = await getHandlungsebenen(
        laufendesProjekt,
        value
      );

      setHandlungsebenen((prevHandlungsebenen) => {
        const newHandlungsebenenArray = [...prevHandlungsebenen];
        newHandlungsebenenArray[index] = newHandlungsebenen;
        return newHandlungsebenenArray;
      });
    },
    [containers, laufendesProjekt, updateContainers]
  );

  /**
   * Ändert die Handlungsebene eines Containers
   * @param {number} index - Index des Containers
   * @param {string} value - Neue Handlungsebene
   */
  const handleHandlungsebeneChange = (index: number, value: string) => {
    updateContainers(
      containers.map((container, i) =>
        i === index ? { ...container, handlungsebene: value } : container
      )
    );
  };

  /**
   * Ändert die Freitextfelder eines Containers
   * @param {number} index - Index des Containers
   * @param {string} field - Name des Freitext-Feldes
   * @param {string} value - Neuer Freitextinhalt
   */
  const handleFreitextChange = (
    index: number,
    field: string,
    value: string
  ) => {
    updateContainers(
      containers.map((container, i) =>
        i === index ? { ...container, [field]: value } : container
      )
    );
    const textarea = document.getElementById(
      `${field}-${index}`
    ) as HTMLTextAreaElement;
    if (textarea) {
      adjustTextareaWidth(textarea);
    }
  };

  /**
   * Passt die Breite eines Textareas an
   * @param {HTMLTextAreaElement} textarea - Das anzupassende Textarea-Element
   */
  const adjustTextareaWidth = (textarea: HTMLTextAreaElement) => {
    const padding = 40;
    textarea.style.width = "auto";
    if (textarea.parentElement) {
      const maxWidth = textarea.parentElement.clientWidth - 40;

      const span = document.createElement("span");
      span.style.visibility = "hidden";
      span.style.whiteSpace = "pre";
      span.style.font = window.getComputedStyle(textarea).font;
      span.textContent = textarea.value || textarea.placeholder;
      document.body.appendChild(span);

      const textWidth = span.getBoundingClientRect().width;
      document.body.removeChild(span);

      const newWidth = Math.max(Math.min(textWidth + padding, maxWidth), 50);
      textarea.style.width = `${newWidth}px`;

      if (textWidth + padding > maxWidth) {
        textarea.style.overflowX = "auto";
      } else {
        textarea.style.overflowX = "hidden";
      }
    } else {
      const textWidth = Math.max(textarea.scrollWidth - padding, 50);
      textarea.style.width = `${textWidth + padding}px`;
    }
  };

  /**
   * Passt alle Textareas in einem Container an
   * @param {HTMLElement} container - Der Container mit den Textareas
   */
  const adjustAllTextareasInContainer = (container: HTMLElement) => {
    const textareas = container.querySelectorAll(
      ".freitext"
    ) as NodeListOf<HTMLTextAreaElement>;
    textareas.forEach(adjustTextareaWidth);
  };

  /**
   * Passt alle Textareas in allen Containern an
   */
  const adjustAllTextareas = useCallback(() => {
    const boxContainers = document.querySelectorAll(".box-container");
    boxContainers.forEach((container) =>
      adjustAllTextareasInContainer(container as HTMLElement)
    );
  }, []);

  useEffect(() => {
    const savedProjektState = localStorage.getItem(laufendesProjekt);
    if (savedProjektState) {
      const parsedState = JSON.parse(savedProjektState);
      setContainers(
        parsedState.containers || [createEmptyContainer(zielgruppen)]
      );

      setTimeout(adjustAllTextareas, 0);
    } else {
      const newContainers = [createEmptyContainer(zielgruppen)];
      setContainers(newContainers);

      setTimeout(adjustAllTextareas, 0);
    }
  }, [laufendesProjekt, zielgruppen, createEmptyContainer]);

  /**
   * Fügt eine neues Element der Qualifikationsebene hinzu
   * @param {string} category - Die Kategorie der Qualifikationsebene
   * @param {string} newItem - Das neue Element
   */
  const handleAddQualifikationsebene = (category: string, newItem: string) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    if (!customData.taxonomie) {
      customData.taxonomie = {};
    }

    if (Array.isArray(customData.taxonomie[category]?.qualifikationsebene)) {
      // Fall 1 Struktur
      customData.taxonomie[category].qualifikationsebene.push(newItem);

      // Überprüft, ob das neue Element bereits als Schlüssel in der Handlungsebene existiert
      const isExistingKey = Object.keys(customData.taxonomie).some(
        (taxonomyKey) =>
          customData.taxonomie[taxonomyKey].handlungsebenen &&
          Object.keys(
            customData.taxonomie[taxonomyKey].handlungsebenen
          ).includes(newItem)
      );

      if (!isExistingKey) {
        if (!customData.taxonomie[category].handlungsebenen) {
          customData.taxonomie[category].handlungsebenen = {};
        }
        customData.taxonomie[category].handlungsebenen[newItem] = [];
      }
    } else {
      // Fall 2 Struktur
      const [taxonomyKey, subCategory] = category.split("-");
      if (!customData.taxonomie[taxonomyKey]) {
        customData.taxonomie[taxonomyKey] = { qualifikationsebene: {} };
      }
      if (!customData.taxonomie[taxonomyKey].qualifikationsebene[subCategory]) {
        customData.taxonomie[taxonomyKey].qualifikationsebene[subCategory] = [];
      }
      customData.taxonomie[taxonomyKey].qualifikationsebene[subCategory].push(
        newItem
      );
    }

    saveCustomTaxonomyData(laufendesProjekt, customData);
    loadQualifikationsebenen();
  };

  /**
   * Bearbeitet ein bestehendes Element in der Qualifikationsebene
   * @param {string} category - Die Kategorie der Qualifikationsebene
   * @param {string} oldItem - Das alte Element
   * @param {string} newItem - Das neue Element
   */
  const handleEditQualifikationsebene = (
    category: string,
    oldItem: string,
    newItem: string
  ) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    if (!customData.taxonomie) {
      return;
    }

    Object.keys(customData.taxonomie).forEach((taxonomyKey) => {
      const taxonomyData = customData.taxonomie[taxonomyKey];

      if (Array.isArray(taxonomyData.qualifikationsebene)) {
        // Fall 1 Struktur
        const index = taxonomyData.qualifikationsebene.indexOf(oldItem);
        if (index !== -1) {
          taxonomyData.qualifikationsebene[index] = newItem;
        }

        // Aktualisiert den Schlüssel in handlungsebenen
        if (
          taxonomyData.handlungsebenen &&
          taxonomyData.handlungsebenen[oldItem]
        ) {
          taxonomyData.handlungsebenen[newItem] =
            taxonomyData.handlungsebenen[oldItem];
          delete taxonomyData.handlungsebenen[oldItem];
        }
      } else if (
        taxonomyData.qualifikationsebene &&
        taxonomyData.handlungsebene
      ) {
        // Fall 2 Struktur
        Object.keys(taxonomyData.qualifikationsebene).forEach((subCategory) => {
          const index =
            taxonomyData.qualifikationsebene[subCategory].indexOf(oldItem);
          if (index !== -1) {
            taxonomyData.qualifikationsebene[subCategory][index] = newItem;
          }
        });
      }
    });

    saveCustomTaxonomyData(laufendesProjekt, customData);
    loadQualifikationsebenen();

    // Alle Container aktuallisieren
    updateContainers((prevContainers) =>
      prevContainers.map((container) =>
        container.qualifikationsebene === oldItem
          ? { ...container, qualifikationsebene: newItem, handlungsebene: "" }
          : container
      )
    );
  };

  /**
   * Löscht ein Element in der Qualifikationsebene
   * @param {string} category - Die Kategorie der Qualifikationsebene
   * @param {string} item - Das zu löschende Element
   */
  const handleDeleteQualifikationsebene = (category: string, item: string) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    if (Array.isArray(customData.taxonomie[category]?.qualifikationsebene)) {
      // Fall 1 Struktur
      customData.taxonomie[category].qualifikationsebene = customData.taxonomie[
        category
      ].qualifikationsebene.filter((i) => i !== item);

      // Prüft, ob das Element in anderen Kategorien noch existiert
      const itemExistsInOtherCategories = Object.keys(
        customData.taxonomie
      ).some(
        (taxonomyKey) =>
          taxonomyKey !== category &&
          Array.isArray(
            customData.taxonomie[taxonomyKey].qualifikationsebene
          ) &&
          customData.taxonomie[taxonomyKey].qualifikationsebene.includes(item)
      );

      // Wenn das Element in keiner Kategorie mehr existiert, lösche es auch aus der Handlungsebene
      if (!itemExistsInOtherCategories) {
        Object.keys(customData.taxonomie).forEach((taxonomyKey) => {
          if (
            customData.taxonomie[taxonomyKey].handlungsebenen &&
            customData.taxonomie[taxonomyKey].handlungsebenen[item]
          ) {
            delete customData.taxonomie[taxonomyKey].handlungsebenen[item];
          }
        });
      }
    } else {
      // Fall 2 Struktur
      const [taxonomyKey, subCategory] = category.split("-");
      if (
        customData.taxonomie?.[taxonomyKey]?.qualifikationsebene?.[subCategory]
      ) {
        customData.taxonomie[taxonomyKey].qualifikationsebene[subCategory] =
          customData.taxonomie[taxonomyKey].qualifikationsebene[
            subCategory
          ].filter((i) => i !== item);
      }
    }

    saveCustomTaxonomyData(laufendesProjekt, customData);
    loadQualifikationsebenen();
  };

  /**
   * Fügt ein neues Element der Handlungsebene hinzu
   * @param {string} category - Die Kategorie der Handlungsebene
   * @param {string} newItem - Das neue Element
   */
  const handleAddHandlungsebene = (category: string, newItem: string) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    let updated = false;

    if (customData.taxonomie) {
      Object.keys(customData.taxonomie).forEach((taxonomyKey) => {
        const taxonomyData = customData.taxonomie[taxonomyKey];
        if (Array.isArray(taxonomyData.qualifikationsebene)) {
          // Fall 1 Struktur
          if (taxonomyData.qualifikationsebene.includes(category)) {
            if (!taxonomyData.handlungsebenen) {
              taxonomyData.handlungsebenen = {};
            }
            if (!taxonomyData.handlungsebenen[category]) {
              taxonomyData.handlungsebenen[category] = [];
            }
            taxonomyData.handlungsebenen[category].push(newItem);
            updated = true;
          }
        } else if (
          taxonomyData.qualifikationsebene &&
          taxonomyData.handlungsebene
        ) {
          // Fall 2 Struktur
          Object.keys(taxonomyData.qualifikationsebene).forEach((qCategory) => {
            if (
              taxonomyData.qualifikationsebene[qCategory].includes(category)
            ) {
              if (!taxonomyData.handlungsebene[qCategory]) {
                taxonomyData.handlungsebene[qCategory] = [];
              }
              taxonomyData.handlungsebene[qCategory].push(newItem);
              updated = true;
            }
          });
        }
      });
    }

    if (updated) {
      saveCustomTaxonomyData(laufendesProjekt, customData);
      setHandlungsebenen((prevHandlungsebenen) =>
        prevHandlungsebenen.map((ebene, index) =>
          containers[index].qualifikationsebene === category
            ? [...ebene, newItem]
            : ebene
        )
      );
    }
  };

  /**
   * Bearbeitet ein bestehendes Element in der Handlungsebene
   * @param {string} category - Die Kategorie der Handlungsebene
   * @param {string} oldItem - Das alte Element
   * @param {string} newItem - Das neue Element
   */
  const handleEditHandlungsebene = (
    category: string,
    oldItem: string,
    newItem: string
  ) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    let updated = false;

    if (customData.taxonomie) {
      Object.keys(customData.taxonomie).forEach((taxonomyKey) => {
        const taxonomyData = customData.taxonomie[taxonomyKey];
        if (Array.isArray(taxonomyData.qualifikationsebene)) {
          // Fall 1 Struktur
          if (
            taxonomyData.handlungsebenen &&
            taxonomyData.handlungsebenen[category]
          ) {
            const index =
              taxonomyData.handlungsebenen[category].indexOf(oldItem);
            if (index !== -1) {
              taxonomyData.handlungsebenen[category][index] = newItem;
              updated = true;
            }
          }
        } else if (
          taxonomyData.qualifikationsebene &&
          taxonomyData.handlungsebene
        ) {
          // Fall 2 Struktur
          Object.keys(taxonomyData.qualifikationsebene).forEach((qCategory) => {
            if (
              taxonomyData.qualifikationsebene[qCategory].includes(category)
            ) {
              const index =
                taxonomyData.handlungsebene[qCategory].indexOf(oldItem);
              if (index !== -1) {
                taxonomyData.handlungsebene[qCategory][index] = newItem;
                updated = true;
              }
            }
          });
        }
      });
    }

    if (updated) {
      saveCustomTaxonomyData(laufendesProjekt, customData);
      setHandlungsebenen((prevHandlungsebenen) =>
        prevHandlungsebenen.map((ebene) =>
          ebene.map((item) => (item === oldItem ? newItem : item))
        )
      );
      updateContainers((prevContainers) =>
        prevContainers.map((container) =>
          container.handlungsebene === oldItem
            ? { ...container, handlungsebene: newItem }
            : container
        )
      );
    }
  };

  /**
   * Löscht ein Element in der Handlungsebene
   * @param {string} category - Die Kategorie der Handlungsebene
   * @param {string} item -Das zu löschende Element
   */
  const handleDeleteHandlungsebene = (category: string, item: string) => {
    const customData = getCustomTaxonomyData(laufendesProjekt) || {
      ...jsonData,
    };
    delete customData.metadaten;

    let updated = false;

    if (customData.taxonomie) {
      Object.keys(customData.taxonomie).forEach((taxonomyKey) => {
        const taxonomyData = customData.taxonomie[taxonomyKey];
        if (Array.isArray(taxonomyData.qualifikationsebene)) {
          // Fall 1 Struktur
          if (
            taxonomyData.handlungsebenen &&
            taxonomyData.handlungsebenen[category]
          ) {
            taxonomyData.handlungsebenen[category] =
              taxonomyData.handlungsebenen[category].filter((i) => i !== item);
            updated = true;
          }
        } else if (
          taxonomyData.qualifikationsebene &&
          taxonomyData.handlungsebene
        ) {
          // Fall 2 Struktur
          Object.keys(taxonomyData.qualifikationsebene).forEach((qCategory) => {
            if (
              taxonomyData.qualifikationsebene[qCategory].includes(category)
            ) {
              taxonomyData.handlungsebene[qCategory] =
                taxonomyData.handlungsebene[qCategory].filter(
                  (i) => i !== item
                );
              updated = true;
            }
          });
        }
      });
    }

    if (updated) {
      saveCustomTaxonomyData(laufendesProjekt, customData);
      setHandlungsebenen((prevHandlungsebenen) =>
        prevHandlungsebenen.map((ebene) => ebene.filter((i) => i !== item))
      );
      updateContainers((prevContainers) =>
        prevContainers.map((container) =>
          container.handlungsebene === item
            ? { ...container, handlungsebene: "" }
            : container
        )
      );
    }
  };

  return (
    <div className="main-container">
      <h1 className="main-title">{jsonData.metadaten.name}</h1>
      {error ? (
        <div className="error-overlay">
          <div className="error-window">
            <FaExclamationTriangle className="error-icon" />
            <h2>Fehler</h2>
            <p>{error}</p>
            <button onClick={() => setError(null)}>Schließen</button>
          </div>
        </div>
      ) : (
        <div className="content-wrapper">
          {containers.map((container, index) => (
            <div key={index} className="box-container">
              <div className="nutzenformulierung-container">
                <label>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={!container.removeHandlungsebene}
                    onChange={(e) =>
                      handleRemoveHandlungsebeneChange(index, e.target.checked)
                    }
                  />
                  &nbsp;Handlungsebene
                </label>
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nutzenformulierung:&nbsp;
                </span>
                <NutzenformulierungDropdown
                  value={container.nutzenformulierung}
                  onChange={(e) =>
                    handleNutzenformulierungChange(index, e.target.value)
                  }
                />
                <div className="button-container">
                  <button
                    className={`copy-button ${
                      container.isCopied ? "copied" : ""
                    }`}
                    onClick={() =>
                      copyToClipboard(containers, index, setContainers)
                    }
                  >
                    {container.isCopied ? <FaCheck /> : <FaCopy />}
                  </button>
                  <button
                    className="delete-button"
                    onClick={() =>
                      deleteContainer(
                        containers,
                        index,
                        updateContainers,
                        adjustAllTextareas
                      )
                    }
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
              <div className="inner-container">
                <ZielgruppenDropdown
                  zielgruppen={zielgruppen}
                  handleZielgruppeChange={handleZielgruppeChange}
                  index={index}
                  selectedZielgruppe={container.zielgruppe}
                  setZielgruppen={setZielgruppen}
                />
                <textarea
                  id={`freitext1-${index}`}
                  className="freitext"
                  placeholder="Freitext"
                  value={container.freitext1}
                  onChange={(e) =>
                    handleFreitextChange(index, "freitext1", e.target.value)
                  }
                  onInput={(e) =>
                    adjustTextareaWidth(e.target as HTMLTextAreaElement)
                  }
                />
                <CustomDropdown
                  options={qualifikationsebenen}
                  value={container.qualifikationsebene}
                  onChange={(value) =>
                    handleQualifikationsebeneChange(index, value)
                  }
                  onAddItem={handleAddQualifikationsebene}
                  onEditItem={handleEditQualifikationsebene}
                  onDeleteItem={handleDeleteQualifikationsebene}
                  placeholder="Wählen Sie ein Kompetenzverb für die Qualifikationsebene"
                  taxonomyType={laufendesProjekt}
                  dropdownType="qualifikationsebene"
                  taxonomyCategory={taxonomyCategory}
                />
                <textarea
                  id={`freitext2-${index}`}
                  className="freitext"
                  placeholder="Freitext"
                  value={container.freitext2}
                  onChange={(e) =>
                    handleFreitextChange(index, "freitext2", e.target.value)
                  }
                  onInput={(e) =>
                    adjustTextareaWidth(e.target as HTMLTextAreaElement)
                  }
                />
                {!container.removeHandlungsebene && (
                  <>
                    {/* 
                    Verbindungswort zwischen Qualifikationsebene und Handlungsebene.
                    Kann mit "sie" erweitert werden - Anpassung muss dann auch in "buttons.tsx" für das kopieren erfolgen.
                    */}
                    <strong>, indem</strong>
                    <textarea
                      id={`freitext3-${index}`}
                      className="freitext"
                      placeholder="Freitext"
                      value={container.freitext3}
                      onChange={(e) =>
                        handleFreitextChange(index, "freitext3", e.target.value)
                      }
                      onInput={(e) =>
                        adjustTextareaWidth(e.target as HTMLTextAreaElement)
                      }
                    />
                    <CustomDropdown
                      options={handlungsebenen[index]}
                      value={container.handlungsebene}
                      onChange={(value) =>
                        handleHandlungsebeneChange(index, value)
                      }
                      onAddItem={(category, newItem) =>
                        handleAddHandlungsebene(
                          container.qualifikationsebene,
                          newItem
                        )
                      }
                      onEditItem={(category, oldItem, newItem) =>
                        handleEditHandlungsebene(
                          container.qualifikationsebene,
                          oldItem,
                          newItem
                        )
                      }
                      onDeleteItem={(category, item) =>
                        handleDeleteHandlungsebene(
                          container.qualifikationsebene,
                          item
                        )
                      }
                      placeholder="Wählen Sie ein Tätigkeitsverb für die Handlungsebene"
                      disabled={!container.qualifikationsebene}
                      taxonomyType={laufendesProjekt}
                      dropdownType="handlungsebene"
                      taxonomyCategory={taxonomyCategory}
                    />
                    <textarea
                      id={`freitext4-${index}`}
                      className="freitext"
                      placeholder="Freitext"
                      value={container.freitext4}
                      onChange={(e) =>
                        handleFreitextChange(index, "freitext4", e.target.value)
                      }
                      onInput={(e) =>
                        adjustTextareaWidth(e.target as HTMLTextAreaElement)
                      }
                    />
                  </>
                )}
                {container.nutzenformulierung !== "keine" && (
                  <>
                    <strong>{container.nutzenformulierung}</strong>
                    <textarea
                      id={`freitext5-${index}`}
                      className="freitext"
                      placeholder="Freitext"
                      value={container.freitext5}
                      onChange={(e) =>
                        handleFreitextChange(index, "freitext5", e.target.value)
                      }
                      onInput={(e) =>
                        adjustTextareaWidth(e.target as HTMLTextAreaElement)
                      }
                    />
                  </>
                )}
                <strong>.</strong>
              </div>
            </div>
          ))}
          <div className="add-container" onClick={handleAddContainer}>
            <FaPlus className="add-icon" />
          </div>
          {!isDropdownActive && (
            <button
              className={`copy-all-button ${isAllCopied ? "copied" : ""}`}
              onClick={() => copyAllToClipboard(containers, setIsAllCopied)}
            >
              {isAllCopied ? <FaCheck /> : <GrCopy />}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default LernzielTemplate;
