import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import ModalInput from "./ModalInput.tsx";

/**
 * @interface CustomDropdownProps
 * @description Eigenschaften für die CustomDropdown-Komponente
 */
interface CustomDropdownProps {
  options: { category: string; items: string[] }[] | string[];
  value: string;
  onChange: (value: string) => void;
  onAddItem: (category: string, newItem: string) => void;
  onEditItem: (category: string, oldItem: string, newItem: string) => void;
  onDeleteItem: (category: string, item: string) => void;
  placeholder: string;
  disabled?: boolean;
  taxonomyType: string;
  dropdownType: "qualifikationsebene" | "handlungsebene";
  taxonomyCategory: string;
}

/**
 * @function CustomDropdown
 * @description Die anpassbare Dropdown-Komponente mit den Bearbeitungsfunktionen
 * @param {CustomDropdownProps} props - Die Eigenschaften der Komponente
 * @returns {JSX.Element} Die gerenderte CustomDropdown-Komponente
 */
const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  value,
  onChange,
  onAddItem,
  onEditItem,
  onDeleteItem,
  placeholder,
  disabled = false,
  taxonomyType,
  dropdownType,
  taxonomyCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newItemText, setNewItemText] = useState<string>("");
  const [addingToCategory, setAddingToCategory] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<string | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<"left" | "right">(
    "right"
  );

  const isEditable = !(
    taxonomyType === "saechsischer_lehrplan" &&
    dropdownType === "qualifikationsebene"
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const calculateDropdownPosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const spaceRight = window.innerWidth - rect.right;
        const spaceLeft = rect.left;

        if (spaceRight < 300 && spaceLeft > spaceRight) {
          setDropdownPosition("left");
        } else {
          setDropdownPosition("right");
        }
      }
    };

    calculateDropdownPosition();

    window.addEventListener("resize", calculateDropdownPosition);

    const observer = new MutationObserver(calculateDropdownPosition);
    if (dropdownRef.current) {
      observer.observe(dropdownRef.current, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    }

    const targetGroupElement = document.querySelector(".target-group-class");
    const textareaElements = document.querySelectorAll(".textarea-class");

    if (targetGroupElement) {
      observer.observe(targetGroupElement, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    }

    textareaElements.forEach((textarea) => {
      observer.observe(textarea, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    });

    return () => {
      window.removeEventListener("resize", calculateDropdownPosition);
      observer.disconnect();
    };
  }, [value]);

  useEffect(() => {
    if (value) {
      let valueExists = false;
      if (Array.isArray(options)) {
        if (options.length > 0 && typeof options[0] === "string") {
          valueExists = (options as string[]).includes(value);
        } else {
          valueExists = (
            options as { category: string; items: string[] }[]
          ).some((group) => group.items.includes(value));
        }
      }

      if (!valueExists) {
        onChange("");
      }
    }
  }, [options, value]);

  /**
   * @function handleAddItem
   * @description Fügt ein neues Element hinzu
   */
  const handleAddItem = () => {
    if (newItemText.trim() && addingToCategory !== null) {
      onAddItem(addingToCategory, newItemText.trim());
      setNewItemText("");
      setAddingToCategory(null);
      setIsAddModalOpen(false);
    }
  };

  /**
   * @function handleEditItem
   * @description Bearbeitet ein bestehendes Element
   */
  const handleEditItem = () => {
    if (newItemText.trim() && editingItem && addingToCategory !== null) {
      onEditItem(addingToCategory, editingItem, newItemText.trim());
      setNewItemText("");
      setEditingItem(null);
      setAddingToCategory(null);
      setIsEditModalOpen(false);
    }
  };

  /**
   * @function handleDeleteItem
   * @description Löscht ein Element
   * @param {string} category - Die Kategorie des zu löschenden Elements
   * @param {string} item - Das zu löschende Element
   */
  const handleDeleteItem = (category: string, item: string) => {
    onDeleteItem(category, item);
    if (value === item) {
      onChange("");
    }
    setIsOpen(true);
  };

  /**
   * @function handleClick
   * @description Behandelt Klick auf Dropdown
   */
  const handleClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  /**
   * @function handleOpenAddModal
   * @description Öffnet das Modal zum Hinzufügen eines neuen Elements
   * @param {string} category - Die Kategorie, zu der das Element hinzugefügt werden soll
   */
  const handleOpenAddModal = (category: string) => {
    setAddingToCategory(category);
    setIsAddModalOpen(true);
  };

  /**
   * @function renderOptions
   * @description Rendert die Optionen des Dropdowns
   * @returns {JSX.Element} Die gerenderten Dropdown-Elemente
   */
  const renderOptions = () => {
    if (Array.isArray(options) && typeof options[0] === "string") {
      return (
        <>
          {options.map((item) => (
            <div key={item} className="dropdown-item">
              <div
                className="dropdown-item-content"
                onClick={() => {
                  onChange(item);
                  setIsOpen(false);
                }}
              >
                {item}
              </div>
              {isEditable && (
                <>
                  <button
                    className="edit-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingItem(item);
                      setNewItemText(item);
                      setAddingToCategory("");
                      setIsEditModalOpen(true);
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="delete-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteItem("", item);
                    }}
                  >
                    <FaTrash />
                  </button>
                </>
              )}
            </div>
          ))}
          {isEditable && (
            <button
              className="add-new-option"
              onClick={() => handleOpenAddModal("")}
            >
              <FaPlus />
            </button>
          )}
        </>
      );
    } else {
      const hasDirectQualifikationsebenen = (options as any[]).every(
        (group) => group.isSelectable
      );

      // Wenn es keine Optionen gibt, aber es sich um eine Handlungsebene handelt,
      // zeigen wir trotzdem den "add-new-option" Button an
      if (options.length === 0 && dropdownType === "handlungsebene") {
        return (
          <div className="dropdown-group">
            {isEditable && (
              <button
                className="add-new-option"
                onClick={() => handleOpenAddModal("")}
              >
                <FaPlus />
              </button>
            )}
          </div>
        );
      }

      return (
        options as {
          category: string;
          items: string[];
          isSelectable: boolean;
        }[]
      ).map((group) => (
        <div key={group.category} className="dropdown-group">
          <div
            className={`${
              hasDirectQualifikationsebenen ? "taxonomie" : "q-ebene-untertitel"
            } ${!group.isSelectable ? "taxonomie" : ""}`}
          >
            {group.category.split("-").pop()}
          </div>
          {group.items.map((item) => (
            <div key={item} className="dropdown-item">
              <div
                className="dropdown-item-content"
                onClick={() => {
                  if (group.isSelectable) {
                    onChange(item);
                    setIsOpen(false);
                  }
                }}
              >
                {item}
              </div>
              {isEditable && group.isSelectable && (
                <>
                  <button
                    className="edit-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingItem(item);
                      setNewItemText(item);
                      setAddingToCategory(group.category);
                      setIsEditModalOpen(true);
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="delete-option"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteItem(group.category, item);
                    }}
                  >
                    <FaTrash />
                  </button>
                </>
              )}
            </div>
          ))}
          {isEditable &&
            (dropdownType === "handlungsebene" || group.isSelectable) && (
              <button
                className="add-new-option"
                onClick={() => handleOpenAddModal(group.category)}
              >
                <FaPlus />
              </button>
            )}
        </div>
      ));
    }
  };

  return (
    <div
      className={`custom-dropdown ${disabled ? "disabled" : ""}`}
      ref={dropdownRef}
    >
      <button
        className={`dropdown-button ${taxonomyCategory}`}
        onClick={handleClick}
      >
        <span>{value || placeholder}</span>
      </button>
      {isOpen && !disabled && (
        <div className={`dropdown-menu ${dropdownPosition}`}>
          {renderOptions()}
        </div>
      )}
      <ModalInput
        isOpen={isAddModalOpen}
        value={newItemText}
        onChange={setNewItemText}
        onSubmit={handleAddItem}
        onClose={() => {
          setIsAddModalOpen(false);
          setNewItemText("");
        }}
        placeholder="neuen Operator hinzufügen"
        submitText="Hinzufügen"
      />
      <ModalInput
        isOpen={isEditModalOpen}
        value={newItemText}
        onChange={setNewItemText}
        onSubmit={handleEditItem}
        onClose={() => {
          setIsEditModalOpen(false);
          setNewItemText("");
        }}
        placeholder="Operator bearbeiten"
        submitText="Speichern"
      />
    </div>
  );
};

export default CustomDropdown;
