//This work is licensed under CC BY-SA 4.0

/**
 * @fileoverview Haupteinstiegspunkt der React-Anwendung.
 * Importiert notwendige Module und rendert die Hauptkomponente.
 */

import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.tsx";
import "./index.css";
import { ProjectProvider } from "./components/laufendesProjekt.tsx";

/**
 * Erstellt das Wurzelelement für die React-Anwendung.
 * @type {ReactDOM.Root}
 */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
/**
 * Rendert die Hauptanwendungskomponente.
 * Verwendet React.StrictMode für zusätzliche Entwicklungschecks.
 * Umschließt die App-Komponente mit dem ProjectProvider für das Projektkontextmanagement.
 */
root.render(
  <React.StrictMode>
    <ProjectProvider>
      <App />
    </ProjectProvider>
  </React.StrictMode>
);
