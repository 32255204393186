import React, { useState, useEffect } from "react";
import "./index.css";
import LernzielTemplate from "./components/lernziel_template.tsx";
import Sidebar from "./components/seitenleiste.tsx";
import Einstellungen from "./components/einstellungen.tsx";
import { MdDarkMode } from "react-icons/md";
import { CiLight } from "react-icons/ci";
import Auswahl from "./components/auswahl.tsx";
import Entwicklung from "./wikieintraege/entwicklung.tsx";
import Impressum from "./wikieintraege/impressum.tsx";
import Lernziele from "./wikieintraege/lernziele.tsx";
import Lernzielarten from "./wikieintraege/lernzielarten.tsx";
import LernzielartenSaechsischerLehrplan from "./wikieintraege/LernzietaxonomieSaechischerLehrplan.tsx";
import DynamischeLehrplanDatenbank from "./wikieintraege/dynamische_lehrplan_datenbank.tsx";
import FeinlernzieleFormulieren from "./wikieintraege/aufbau_lernziele.tsx";
import LernzielBeispiele from "./wikieintraege/lernziele_beispiele.tsx";
import {
  useProjectContext,
  ProjectProvider,
} from "./components/laufendesProjekt.tsx";
import LaufendesProjektLink from "./components/laufendesProjekt.tsx";

/**
 * Deklaration für die require-Funktion, die vom Webpack-Kontext verwendet wird.
 */
declare const require: {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp
  ) => {
    keys(): string[];
    <T>(id: string): T;
  };
};

/**
 * Typ-Definition für die verschiedenen Ansichten in der Anwendung.
 */
export type ViewType =
  | "home"
  | "Entwicklung"
  | "Impressum"
  | "Lernziele"
  | "Lernzielarten"
  | "LernzielartenSaechsischerLehrplan"
  | "DynamischeLehrplanDatenbank"
  | "FeinlernzieleFormulieren"
  | "LernzielBeispiele"
  | "laufendes-projekt"
  | string;

/**
 * Hauptkomponente für den Inhalt der Anwendung.
 * Verwaltet den Zustand und rendert die Benutzeroberfläche.
 */
function AppContent() {
  const { laufendesProjekt, setLaufendesProjekt } = useProjectContext();
  const [view, setView] = useState<ViewType>("home");
  const [startseite, setStartseite] = useState<boolean>(true);
  /* Prüft ob darkMode bereits im Local Storage gesetzt ist.
  Wenn es bereits exestiert, dann wird der der jeweilige Modus gewählt
  Wenn es noch nicht exestiert, wird es auf false gesetzt (white-mode)*/
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode === null) {
      localStorage.setItem("darkMode", "false");
      return false;
    }
    return JSON.parse(savedDarkMode);
  });
  const [isDropdownActive, setDropdownActive] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [jsonData, setJsonData] = useState<Record<string, any>>({});

  // Dynamisches laden der JSON-Dateien (speichern im State)
  useEffect(() => {
    const loadJsonFiles = () => {
      const context = require.context("./lernziele", false, /\.json$/);
      const files: Record<string, any> = {};
      context.keys().forEach((key) => {
        const fileName = key.replace("./", "").replace(".json", "");
        files[fileName] = context(key);
      });
      setJsonData(files);
    };

    loadJsonFiles();
  }, []);

  /**
   * Ändert die aktuelle Ansicht.
   * @param {ViewType} view - Die neue Ansicht.
   */
  const handleSelect = (view: ViewType) => {
    setView(view);
    setStartseite(view === "home");
  };
  // Umschalten in den Dark Mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", JSON.stringify(newMode));
      document.body.classList.toggle("dark-mode", newMode);
      document.body.style.backgroundColor = newMode ? "#222831" : "#EEEEEE";
      return newMode;
    });
  };
  // Lädt das aktuelle Projekt nach Aktualisieren der Webseite
  useEffect(() => {
    const savedView = localStorage.getItem("laufendesProjekt");
    if (savedView) {
      setView(savedView as ViewType);
      setStartseite(false);
    }
    // Setze den Hintergrund basierend auf dem gespeicherten Dark-Mode-Zustand
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode) {
      const isDarkMode = JSON.parse(savedDarkMode);
      document.body.classList.toggle("dark-mode", isDarkMode);
      document.body.style.backgroundColor = isDarkMode ? "#222831" : "#EEEEEE";
    }
  }, []);

  const triggerReload = () => {
    setReloadTrigger((prev) => prev + 1);
  };

  return (
    <div className={`app ${darkMode ? "dark-mode" : ""}`}>
      <Sidebar
        setView={handleSelect}
        setStartseite={setStartseite}
        currentView={view}
      />
      <div className="top-bar">
        <button className="dark-mode-button" onClick={toggleDarkMode}>
          {darkMode ? <CiLight size={24} /> : <MdDarkMode size={24} />}
        </button>
        <Einstellungen
          setView={handleSelect}
          setDropdownActive={setDropdownActive}
          triggerReload={triggerReload}
          jsonData={jsonData}
          laufendesProjekt={laufendesProjekt}
          setLaufendesProjekt={setLaufendesProjekt}
        />
      </div>
      {!startseite &&
        !Object.keys(jsonData).includes(view) &&
        !isDropdownActive && (
          <div className="laufendes-projekt-seitenleiste">
            <LaufendesProjektLink setView={handleSelect} />
          </div>
        )}
      <div className="main-content">
        <InnerApp
          view={view}
          setView={handleSelect}
          startseite={startseite}
          setStartseite={setStartseite}
          jsonData={jsonData}
          isDropdownActive={isDropdownActive}
        />
      </div>
    </div>
  );
}
/**
 * Innere Komponente für die Startseite und der verschiedenen Ansichten.
 * @param {Object} props - Die Eigenschaften der Komponente.
 * @param {ViewType} props.view - Die aktuelle Ansicht.
 * @param {function} props.setView - Funktion zum Setzen der Ansicht.
 * @param {boolean} props.startseite - Gibt an, ob die Startseite angezeigt wird.
 * @param {function} props.setStartseite - Funktion zum Setzen des Startseiten-Status.
 * @param {number} props.reloadTrigger - Auslöser zum Neuladen.
 * @param {Record<string, any>} props.jsonData - JSON-Daten für die Ansichten.
 * @param {boolean} props.isDropdownActive - Gibt an, ob das Dropdown aktiv ist.
 */
function InnerApp({
  view,
  setView,
  startseite,
  setStartseite,
  jsonData,
  isDropdownActive,
}: {
  view: ViewType;
  setView: (view: ViewType) => void;
  startseite: boolean;
  setStartseite: (startseite: boolean) => void;
  jsonData: Record<string, any>;
  isDropdownActive: boolean;
}) {
  const { laufendesProjekt, setLaufendesProjekt } = useProjectContext();

  /**
   * Generiert die Badges der Karten.
   * @param {Record<string, any>} data - Die Daten für die Badges.
   * @returns {Array<{text: string, color: string}>} Ein Array von Badge-Objekten.
   */
  const getBadgesForData = (
    data: Record<string, any>
  ): { text: string; color: string }[] => {
    const bannerColors = data.metadaten?.["banner farbe"] || ["#A91D3A"];
    let badges: string[] = [];

    if (data.taxonomie) {
      badges = Object.keys(data.taxonomie).filter(
        (key) =>
          data.taxonomie[key] && Object.keys(data.taxonomie[key]).length > 0
      );
    } else if (data.qualifikationsebenen) {
      badges = Object.keys(data.qualifikationsebenen);
    }

    return badges.map((badge, index) => ({
      text: badge,
      color: bannerColors[index % bannerColors.length],
    }));
  };

  /**
   * Rendert die Karten auf der Startseite.
   * @returns {JSX.Element[]} Ein Array von Auswahl-Komponenten.
   */
  const renderCards = () => {
    return Object.entries(jsonData).map(([key, data]) => (
      <Auswahl
        key={key}
        empfohlen={data.metadaten?.empfohlen === true}
        badges={getBadgesForData(data)}
        title={data.metadaten?.name || ""}
        text={data.metadaten?.beschreibung || ""}
        onSelect={() => {
          setView(key as ViewType);
          setLaufendesProjekt(key);
          setStartseite(false);
        }}
      />
    ));
  };

  return (
    <div className="app">
      {startseite && view === "home" ? (
        <div className="App">
          <div className="nebeneinander">{renderCards()}</div>
        </div>
      ) : null}
      {typeof view === "string" && Object.keys(jsonData).includes(view) && (
        <LernzielTemplate
          jsonData={jsonData[view]}
          isDropdownActive={isDropdownActive}
        />
      )}
      {view === "Entwicklung" && <Entwicklung />}
      {view === "Impressum" && <Impressum />}
      {view === "Lernziele" && <Lernziele />}
      {view === "Lernzielarten" && <Lernzielarten />}
      {view === "LernzielartenSaechsischerLehrplan" && (
        <LernzielartenSaechsischerLehrplan />
      )}
      {view === "DynamischeLehrplanDatenbank" && (
        <DynamischeLehrplanDatenbank />
      )}
      {view === "FeinlernzieleFormulieren" && <FeinlernzieleFormulieren />}
      {view === "LernzielBeispiele" && <LernzielBeispiele />}
      {view === "laufendes-projekt" ? (
        <div>
          {laufendesProjekt &&
          typeof laufendesProjekt === "string" &&
          jsonData[laufendesProjekt] ? (
            <LernzielTemplate
              jsonData={jsonData[laufendesProjekt]}
              isDropdownActive={isDropdownActive}
            />
          ) : (
            <div>Kein laufendes Projekt ausgewählt</div>
          )}
        </div>
      ) : null}
    </div>
  );
}

/**
 * Hauptkomponente der Anwendung.
 * Umhüllt den Inhalt mit dem ProjectProvider.
 * @returns {JSX.Element} Die gerenderte App-Komponente.
 */
function App() {
  return (
    <ProjectProvider>
      <AppContent />
    </ProjectProvider>
  );
}

export default App;
