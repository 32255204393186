import React, { useRef } from "react";

/**
 * Props für die ModalInput-Komponente.
 * @interface ModalInputProps
 */
interface ModalInputProps {
  isOpen: boolean;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
  placeholder: string;
  submitText: string;
}

/**
 * ModalInput-Komponente.
 * Öffnet ein Modal (Eingabefeld) mit Bestätigungs- und Abbruchbutton.
 * @param {ModalInputProps} props - Die Props für die Komponente.
 * @returns {React.ReactElement | null} Die gerenderte Komponente oder null, wenn nicht geöffnet.
 */
const ModalInput: React.FC<ModalInputProps> = ({
  isOpen,
  value,
  onChange,
  onSubmit,
  onClose,
  placeholder,
  submitText,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  /**
   * Behandelt Tastatureingaben im Eingabefeld.
   * @param {React.KeyboardEvent<HTMLInputElement>} e - Das Keyboard-Event.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    } else if (e.key === "Escape") {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="universal-alert-overlay">
      <div className="universal-alert" ref={modalRef}>
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
        />
        <button onClick={onSubmit} className="universal-button">
          {submitText}
        </button>
        <button onClick={onClose} className="universal-button">
          Abbrechen
        </button>
      </div>
    </div>
  );
};

export default ModalInput;
