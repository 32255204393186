import React from "react";

/**
 * Die Props (Eigenschaften) für die Auswahl-Komponente.
 * @typedef {Object} AuswahlProps
 * @property {boolean} [empfohlen] - Optional. Gibt an, ob die Auswahl als empfohlen markiert werden soll.
 * @property {{ text: string; color: string }[]} badges - Ein Array von Badge-Objekten mit Text und Farbe.
 * @property {string} title - Der Titel der Auswahl.
 * @property {string} text - Der Beschreibungstext der Auswahl.
 * @property {() => void} onSelect - Callback-Funktion, die bei Auswahl aufgerufen wird.
 */
type AuswahlProps = {
  empfohlen?: boolean;
  badges: { text: string; color: string }[];
  title: string;
  text: string;
  onSelect: () => void;
};

/**
 * Gibt das Grundgerüst der dynamisch erstellten Cards auf der Startseite vor.
 * @param {AuswahlProps} props - Die Eigenschaften der Komponente.
 * @returns {JSX.Element} Die gerenderte Auswahl-Komponente.
 */
function Auswahl(props: AuswahlProps) {
  const { badges, title, text, onSelect, empfohlen } = props;
  return (
    <button
      className="Auswahl"
      onClick={(e) => {
        e.preventDefault();
        onSelect();
      }}
    >
      <div className="badge-container">
        {empfohlen && <span className="empfohlen">💖 Empfohlen</span>}
        {badges.map((badge, index) => (
          <span
            key={index}
            className="badge"
            style={{ backgroundColor: badge.color }}
          >
            {badge.text}
          </span>
        ))}
      </div>
      <div className="auswahl-content">
        <h3 className="auswahl-title">{title}</h3>
        <p className="auswahl-text">{text}</p>
      </div>
    </button>
  );
}

export default Auswahl;
