import React, { useState, useRef, useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

/**
 * @typedef {Object} ZielgruppenDropdownProps
 * @property {string[]} zielgruppen - Liste der verfügbaren Zielgruppen
 * @property {function} handleZielgruppeChange - Funktion zum Ändern der ausgewählten Zielgruppe
 * @property {number} index - Index der aktuellen Zielgruppe
 * @property {string} selectedZielgruppe - Aktuell ausgewählte Zielgruppe
 * @property {function} setZielgruppen - Funktion zum Aktualisieren der Zielgruppen-Liste
 */

/**
 * Komponente für ein Dropdown-Menü zur Auswahl und Verwaltung von Zielgruppen
 * @param {ZielgruppenDropdownProps} props - Die Props für die Komponente
 * @returns {JSX.Element} Gerenderte Dropdown-Komponente
 */
const ZielgruppenDropdown = ({
  zielgruppen,
  handleZielgruppeChange,
  index,
  selectedZielgruppe,
  setZielgruppen,
}) => {
  // Zustandsvariablen
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newZielgruppe, setNewZielgruppe] = useState("");
  const [editZielgruppe, setEditZielgruppe] = useState(false);
  const [editZielgruppeValue, setEditZielgruppeValue] = useState("");
  const [editZielgruppeIndex, setEditZielgruppeIndex] = useState(null);
  // Initialisierung der Zielgruppen aus dem localStorage oder den Props
  const [zielgruppenState, setZielgruppenState] = useState(() => {
    const savedZielgruppen = localStorage.getItem("zielgruppen");
    return savedZielgruppen ? JSON.parse(savedZielgruppen) : zielgruppen;
  });
  const [selected, setSelected] = useState(zielgruppenState[0]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // Refs für DOM-Elemente
  const dropdownRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const editModalRef = useRef<HTMLDivElement>(null);

  // Platzieren der ausgewählten Zielgruppe
  useEffect(() => {
    if (isFirstLoad) {
      setSelected(zielgruppenState[0]);
      setIsFirstLoad(false);
    } else if (selectedZielgruppe) {
      setSelected(selectedZielgruppe);
    }
  }, [selectedZielgruppe, zielgruppenState, isFirstLoad]);

  useEffect(() => {
    /**
     * Schließt Dropdown und Modal bei Drücken der ESC-Taste
     * @param {KeyboardEvent} event - Das Tastatur-Event
     */
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
        setIsModalOpen(false);
        setEditZielgruppe(false);
      }
    };
    /**
     * Schließt Dropdown und Modal bei Klick außerhalb des Dropdowns
     * @param {MouseEvent} event - Das Maus-Event
     */
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
      if (
        editModalRef.current &&
        !editModalRef.current.contains(event.target)
      ) {
        setEditZielgruppe(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Umschalten des Dropdowns
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Öffnen des Modals für neue Zielgruppe
  const handleAddNewZielgruppe = () => {
    setIsModalOpen(true);
  };

  // Hinzufügen einer neuen Zielgruppe in den local Storage
  const handleModalSubmitZielgruppe = () => {
    if (newZielgruppe) {
      setZielgruppenState((prevZielgruppen) => {
        const updatedZielgruppen = [...prevZielgruppen, newZielgruppe];
        localStorage.setItem("zielgruppen", JSON.stringify(updatedZielgruppen));
        setZielgruppen(updatedZielgruppen);
        return updatedZielgruppen;
      });
    }
    setIsModalOpen(false);
    setNewZielgruppe("");
  };

  // Bearbeiten einer Zielgruppe
  const handleEditZielgruppeSubmit = () => {
    if (editZielgruppeValue && editZielgruppeIndex !== null) {
      setZielgruppenState((prevZielgruppen) => {
        const updatedZielgruppen = prevZielgruppen.map((z, i) =>
          i === editZielgruppeIndex ? editZielgruppeValue : z
        );
        localStorage.setItem("zielgruppen", JSON.stringify(updatedZielgruppen));
        setZielgruppen(updatedZielgruppen);

        // Aktualisieren der ausgewählte Zielgruppe, wenn sie bearbeitet wurde
        if (selected === prevZielgruppen[editZielgruppeIndex]) {
          setSelected(editZielgruppeValue);
          handleZielgruppeChange(index, editZielgruppeValue);
        }

        return updatedZielgruppen;
      });
    }
    setEditZielgruppe(false);
    setEditZielgruppeValue("");
    setEditZielgruppeIndex(null);
  };

  /**
   * Löscht eine Zielgruppe
   * @param {string} option - Die zu löschende Zielgruppe
   */
  const deleteZielgruppeOption = (option) => {
    setZielgruppenState((prevZielgruppen) => {
      const updatedZielgruppen = prevZielgruppen.filter((z) => z !== option);
      localStorage.setItem("zielgruppen", JSON.stringify(updatedZielgruppen));
      setZielgruppen(updatedZielgruppen);

      // Wenn die gelöschte Option die ausgewählte war, wird die nächste Zielgruppe ausgewählt
      if (selected === option) {
        const nextIndex = prevZielgruppen.indexOf(option);
        const nextZielgruppe =
          updatedZielgruppen[nextIndex] || updatedZielgruppen[0] || "";
        setSelected(nextZielgruppe);
        handleZielgruppeChange(index, nextZielgruppe);
      }

      return updatedZielgruppen;
    });
  };

  /**
   * Ändert die ausgewählte Zielgruppe und verschiebt sie an die Spitze der Liste
   * @param {number} index - Der Index der Zielgruppe
   * @param {string} option - Die ausgewählte Zielgruppe
   */
  const handleZielgruppeChangeAndMoveToTop = (index, option) => {
    handleZielgruppeChange(index, option);
    setSelected(option);
    setZielgruppenState((prevZielgruppen) => {
      const newZielgruppen = prevZielgruppen.filter((z) => z !== option);
      const updatedZielgruppen = [option, ...newZielgruppen];
      localStorage.setItem("zielgruppen", JSON.stringify(updatedZielgruppen));
      setZielgruppen(updatedZielgruppen);
      return updatedZielgruppen;
    });
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        {selected}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {zielgruppenState.map((option, i) => (
            <div className="dropdown-item" key={i}>
              <span
                className="dropdown-item-content"
                onClick={() =>
                  handleZielgruppeChangeAndMoveToTop(index, option)
                }
              >
                {option}
              </span>
              <button
                className="edit-option"
                onClick={(e) => {
                  e.preventDefault();
                  setEditZielgruppeIndex(i);
                  setEditZielgruppeValue(option);
                  setEditZielgruppe(true);
                }}
              >
                <FaEdit />
              </button>
              <button
                className="delete-option"
                onClick={(e) => {
                  e.preventDefault();
                  deleteZielgruppeOption(option);
                }}
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <div className="add-new-option" onClick={handleAddNewZielgruppe}>
            <span>
              <FaPlus />
            </span>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="universal-alert-overlay">
          <div className="universal-alert" ref={modalRef}>
            <input
              type="text"
              value={newZielgruppe}
              onChange={(e) => setNewZielgruppe(e.target.value)}
              placeholder="Neue Zielgruppe hinzufügen"
            />
            <button
              onClick={handleModalSubmitZielgruppe}
              className="universal-button"
            >
              Hinzufügen
            </button>
          </div>
        </div>
      )}
      {editZielgruppe && (
        <div className="universal-alert-overlay">
          <div className="universal-alert" ref={editModalRef}>
            <input
              type="text"
              value={editZielgruppeValue}
              onChange={(e) => setEditZielgruppeValue(e.target.value)}
              placeholder="Zielgruppe bearbeiten"
            />
            <button
              onClick={handleEditZielgruppeSubmit}
              className="universal-button"
            >
              Speichern
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZielgruppenDropdown;
