import React from "react";

function FeinlernzieleFormulieren() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Formulieren von Feinlernziele</h1>
      <h2 className="wiki-untertitel">Die Grundlagen</h2>
      <p className="wiki-text">
        Operationalisierbare Doppelverb-Feinlernziele werden benötigt, um das zu
        erreichende Ziel transparent zwischen der Lehrperson und den Lernenden
        zu vermitteln. Sie werden vor der Planung der Lehreinheit ausgearbeitet
        und dienen somit als Kontrolleinheit bei der Evaluation.
        <p className="text-quelle">(Meyerhoff & Brühl, 2017, S. 14)</p>
      </p>
      <p className="wiki-text">
        Bei der Formulierung der Doppelverb-Feinlernziele muss darauf geachtet
        werden, dass diese positiv und in Gegenwart erfolgt.
        Wunschformulierungen wie: „Die Lernenden sollen keine Fehler mehr
        machen“ sind nicht geeignet. Eine positiv orienteire
        Gegenwartsformulierung wäre hingegen: „Die Lernenden wenden das Schema
        korrekt an“.
        <p className="text-quelle">(Meyerhoff & Brühl, 2017, S. 16-17)</p>
      </p>

      <h2 className="wiki-untertitel">
        Der Aufbau eines operationalisierbaren Doppelverb-Feinlernziels
      </h2>
      <img
        src={`/assets/Feinlernziel.svg`}
        alt=": Operationalisierbare Doppelverb-Feinlernziele"
        className="wiki-image"
      />
      <p className="img-quelle">
        Operationalisierbare Doppelverb-Feinlernziele (David Root 2024 Vgl.
        Lerche, 2022, S. 81; Meyerhoff & Brühl, 2017, S. 17-18)
      </p>
      <p className="wiki-text">
        Feinlernziel werden in die zwei verpflichtenden Teile -
        Qualifikationsebene und Handlungsebene unterteilt. Die
        Qualifikationsebene enthält neben der Zielgruppe auch das Kompetenzverb.
        Dieses wird verwendet, um die nicht direkt beobachtbaren Fähigkeiten
        oder Kompetenzen zu beschreiben. Das in der Handlungsebene verwendete
        Tätigkeitsverb zielt dabei auf die beobachtbare Aktion der Lernenden ab.
        Auch können optional in der Handlungsebene die Situation, das Objekt und
        die verwendeten Hilfsmittel geschildert werden. Verbunden wird die
        Qualifikationsebene mit der Handlungsebene durch das „ , indem sie“ oder
        wahlweise nur durch „indem“.
        <p className="text-quelle">
          (Lerche, 2022, S. 81; Meyerhoff & Brühl, 2017, S. 17)
        </p>
      </p>
      <p className="wiki-text">
        Zusätzlich kann eine dritte Ebene verwendet werden - die
        Nutzenformulierung. Diese wird durch die Verbindungswörter „ , um“ oder
        „ , damit“ an die Handlungsebene gesetzt. Mit dieser wird die
        Dringlichkeit der zu erwerbenden Fähigkeiten und Kompetenzen betont.
        <p className="text-quelle">(Meyerhoff & Brühl, 2017, S. 18)</p>
      </p>

      <h2 className="wiki-untertitel">
        Beispiel für ein vollständig operationalisiertes Doppelverb-Feinlernziel
      </h2>
      <p className="wiki-text-aufbau">
        <span className="wiki-qualifikationsebene">
          <span className="wiki-zielgruppe">
            <strong>
              Die Lernenden<span className="wiki-klammern">(Zielgruppe)</span>
            </strong>
          </span>
          <span className="wiki-kompetenzverb">
            <strong>
              {" "}
              wenden<span className="wiki-klammern">(Kompetenzverb)</span>
            </strong>
          </span>{" "}
          ihre Kenntnisse zum Thema Schneidtechniken
          <span className="wiki-kompetenzverb">
            <strong> an</strong>
          </span>
        </span>{" "}
        <span className="wiki-trennung">
          <strong> , indem sie</strong>
        </span>{" "}
        <span className="wiki-handlungsebene">
          in Einzelarbeit<span className="wiki-klammern">(Situation)</span>,
          beim Gemüseschneiden<span className="wiki-klammern">(Objekt)</span>{" "}
          die Schnitte korrekt unter zu Hilfenahme des Lehrbuches
          <span className="wiki-klammern">(Hilfsmittel)</span>{" "}
          <span className="wiki-tätigkeitsverb">
            <strong>
              {" "}
              ausführen<span className="wiki-klammern">(Tätigkeitsverb)</span>
            </strong>
          </span>
        </span>{" "}
        <span className="wiki-trennung">
          <strong> , damit </strong>
        </span>{" "}
        <span className="wiki-nutzenformulierung">
          das Verletzungsrisiko beim Schneiden minimiert wird.
        </span>
        <div className="wiki-legende">
          <span className="wiki-qualifikationsebene">
            &nbsp;Qualifikationsebene&nbsp;
          </span>
          <span className="wiki-handlungsebene">
            &nbsp;Handlungsebene&nbsp;
          </span>
          <span className="wiki-nutzenformulierung">
            &nbsp;Nutzenformulierung&nbsp;
          </span>
        </div>
        <p className="text-quelle">
          (David Root 2024 Vgl. Lerche, 2022, S. 81; Meyerhoff & Brühl, 2017, S.
          17-18)
        </p>
      </p>
      <h2 className="wiki-literatur">Literatur</h2>
      <p className="wiki-literatur-text">
        Lerche, T. (2022). Leistungsbeurteilung an Schulen. Lehrstuhl für
        Schulpädagogik, Ludwig-Maximilians-Universität München.
        <br></br>
        <br></br>
        Meyerhoff, J., & Brühl, C. (2017). Fachwissen lebendig vermitteln: Das
        Methodenhandbuch für Trainer und Dozenten (4., überarbeitete Auflage).
        Springer Gabler.
      </p>
    </div>
  );
}

export default FeinlernzieleFormulieren;
