// Präfix für benutzerdefinierte Schlüssel im Local Storage
const CUSTOM_PREFIX = "Benutzerdefinierte_";

/**
 * Lädt Taxonomie-Daten dynamisch aus einer JSON-Datei.
 * @param {string} taxonomyType - Der Typ der zu ladenden Taxonomie.
 * @returns {Promise<any>} Die geladenen Taxonomie-Daten oder null bei einem Fehler.
 */
async function getTaxonomyData(taxonomyType: string): Promise<any> {
    try {
        const module = await import(`../lernziele/${taxonomyType}.json`);
        return module.default;
      } catch (error) {
        console.error(`Fehler beim Laden der Taxonomie-Daten für ${taxonomyType}:`, error);
        return null;
      }
    }

/**
 * Generiert einen benutzerdefinierten Schlüssel für den Local Storage.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @returns {string} Der generierte Schlüssel.
 */
export function getCustomStorageKey(taxonomyType: string): string {
  return `${CUSTOM_PREFIX}${taxonomyType}`;
}

/**
 * Ruft benutzerdefinierte Taxonomie-Daten aus dem Local Storage ab.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @returns {any} Die geparsten Daten oder null, wenn keine Daten gefunden wurden.
 */
export function getCustomTaxonomyData(taxonomyType: string): any {
  const storageKey = getCustomStorageKey(taxonomyType);
  const customData = localStorage.getItem(storageKey);
  return customData ? JSON.parse(customData) : null;
}

/**
 * Speichert benutzerdefinierte Taxonomie-Daten im Local Storage.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @param {any} data - Die zu speichernden Daten.
 */
export function saveCustomTaxonomyData(taxonomyType: string, data: any): void {
  const storageKey = getCustomStorageKey(taxonomyType);
  const dataWithoutMetadata = { ...data };
  delete dataWithoutMetadata.metadaten;
  localStorage.setItem(storageKey, JSON.stringify(dataWithoutMetadata));
}

/**
 * Ruft die Qualifikationsebenen für eine bestimmte Taxonomie ab.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @returns {Promise<Array<{category: string, items: string[], isSelectable: boolean}> | {error: string}>} 
 * Ein Array von Qualifikationsebenen oder ein Fehler-Objekt.
 */
export async function getQualifikationsebenen(taxonomyType: string): Promise<{ category: string; items: string[]; isSelectable: boolean }[] | { error: string }> {
  const data = await getTaxonomyData(taxonomyType);
  const customData = getCustomTaxonomyData(taxonomyType);
  
  if (!data && !customData) return [];
  
  let result: { category: string; items: string[]; isSelectable: boolean }[] = [];

  const processData = (sourceData: any) => {
    if (sourceData?.taxonomie) {
      const processedData = Object.entries(sourceData.taxonomie).flatMap(([taxonomyKey, taxonomyValue]: [string, any]) => {
        if (Array.isArray(taxonomyValue?.qualifikationsebene)) {
          // Struktur für Fall 1
          return [
            { category: taxonomyKey, items: taxonomyValue.qualifikationsebene, isSelectable: true }
          ];
        } else if (typeof taxonomyValue?.qualifikationsebene === 'object') {
          // Struktur für Fall 2
          return [
            { category: taxonomyKey, items: [], isSelectable: false },
            ...Object.entries(taxonomyValue.qualifikationsebene).map(([category, items]) => ({
              category: `${taxonomyKey}-${category}`,
              items: items as string[],
              isSelectable: true
            }))
          ];
        }
        return [];
      });

      return processedData;
    }
    
    return [];
  };

  result = processData(data);

  // Verarbeitet benutzerdefinierte Daten, falls vorhanden
  if (customData) {
    const customResult = processData(customData);
    // Benutzerdefinierte Daten hinzufügen oder ersetzen
    result = customResult.length > 0 ? customResult : result;
  }

  if (result.length === 0) {
    return { error: "Die Struktur der Konfigurationsdatei entspricht nicht den notwendigen Konventionen" };
  }

  return result;
}

/**
 * Ruft die Handlungsebenen für eine bestimmte Taxonomie und ein ausgewähltes Item ab.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @param {string} selectedItem - Das ausgewählte Item.
 * @returns {Promise<string[]>} Ein Array von Handlungsebenen.
 */
export async function getHandlungsebenen(taxonomyType: string, selectedItem: string): Promise<string[]> {
  const customData = getCustomTaxonomyData(taxonomyType);
  const data = customData || await getTaxonomyData(taxonomyType);

  if (!data) return [];

  let result: string[] = [];

  const processData = (sourceData: any) => {
    if (sourceData?.taxonomie) {
      Object.entries(sourceData.taxonomie).forEach(([taxonomyKey, taxonomyValue]: [string, any]) => {
        if (taxonomyValue && typeof taxonomyValue === 'object') {
          if (Array.isArray(taxonomyValue.qualifikationsebene)) {
            // Struktur für Fall 1
            if (taxonomyValue.qualifikationsebene.includes(selectedItem)) {
              result = result.concat(taxonomyValue.handlungsebenen[selectedItem] || []);
            }
          } else if (taxonomyValue.qualifikationsebene && taxonomyValue.handlungsebene) {
            // Struktur für Fall 2
            Object.entries(taxonomyValue.qualifikationsebene).forEach(([category, items]) => {
              if ((items as string[]).includes(selectedItem)) {
                result = result.concat(taxonomyValue.handlungsebene[category] || []);
              }
            });
          }
        }
      });
    }
  };

  processData(data);

  return [...new Set(result)];
}

/**
 * Bestimmt die Kategorie einer Taxonomie für die dynamische Hintergrundfarbe.
 * @param {string} taxonomyType - Der Typ der Taxonomie.
 * @returns {Promise<string>} Die bestimmte Kategorie oder 'unknown'.
 */
export async function determineCategory(taxonomyType: string): Promise<string> {
  const data = getCustomTaxonomyData(taxonomyType) || await getTaxonomyData(taxonomyType);
  
  if (data?.taxonomie) {
    const categories = Object.keys(data.taxonomie).filter(key => 
      data.taxonomie[key] && typeof data.taxonomie[key] === 'object' && 
      (data.taxonomie[key].qualifikationsebene || data.taxonomie[key].handlungsebene)
    );
    return categories.length > 0 ? categories.join('-') : 'unknown';
  }

  return 'unknown';
}