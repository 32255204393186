import React from "react";

function DynamischeLehrplanDatenbank() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Sächsische Lehrplan-Datenbank</h1>
      <h2 className="wiki-untertitel">Allgemeinbildende Schulen</h2>
      <div className="wiki-datenbank">
        <p className="wiki-text">
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=1"
            target="_blank"
          >
            Vollständige Liste
          </a>
        </p>
        <p className="wiki-text-link">
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=1&lplanschulartid=1"
            target="_blank"
          >
            Grundschule
          </a>
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=1&lplanschulartid=4"
            target="_blank"
          >
            Oberschule
          </a>
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=1&lplanschulartid=2"
            target="_blank"
          >
            Gymnasium
          </a>
        </p>
        <br></br>
        <h2 className="wiki-untertitel">Berufsbildende Schulen</h2>
        <p className="wiki-text">
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=2"
            target="_blank"
          >
            Vollständige Liste{" "}
          </a>
        </p>
        <p className="wiki-text-link">
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=2&lplanschulartid=7000"
            target="_blank"
          >
            Berufsschule
          </a>
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=2&lplanschulartid=22"
            target="_blank"
          >
            Fachoberschule
          </a>
          <a
            href="https://www.schulportal.sachsen.de/lplandb/index.php?sg=2&lplanschulartid=12"
            target="_blank"
          >
            Berufliches Gymnasium
          </a>
        </p>
      </div>
    </div>
  );
}

export default DynamischeLehrplanDatenbank;
