import React, { useState, useEffect } from "react";

/**
 * @typedef {Object} SubItem
 * @property {string} label - Text für das Unterelement
 * @property {string} view - Ansicht für das Unterelement
 */

/**
 * @typedef {Object} Link
 * @property {string} label - Text für den Link
 * @property {string} view - Ansicht für den Link
 * @property {SubItem[]} [subItems] - Optionale Unterelemente für den Link
 */

/**
 * @typedef {Object} SidebarProps
 * @property {function} setView - Funktion zum Setzen der aktuellen Ansicht
 * @property {function} setStartseite - Funktion zum Setzen des Startseiten-Status
 * @property {string} currentView - Die aktuell angezeigte Ansicht
 */

/**
 * Sidebar-Komponente für die Navigation
 * @param {SidebarProps} props - Die Props für die Sidebar-Komponente
 */
const Sidebar = ({ setView, setStartseite, currentView }) => {
  const [activeView, setActiveView] = useState("");
  const [isWikiExpanded, setIsWikiExpanded] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);

  /** @type {Link[]} */
  const links = [
    { label: "Startseite", view: "home" },
    {
      label: "Über diese App",
      view: "about",
      subItems: [
        { label: "Entwicklung", view: "Entwicklung" },
        { label: "Impressum", view: "Impressum" },
      ],
    },
    {
      label: "Wiki",
      view: "Wiki",
      subItems: [
        { label: "Lernziele", view: "Lernziele" },
        {
          label: "Lernzielarten",
          view: "Lernzielarten",
        },
        {
          label: "Lernzietaxonomie - Sächsischer Lehrplan",
          view: "LernzielartenSaechsischerLehrplan",
        },
        {
          label: "Formulieren von Feinlernzielen",
          view: "FeinlernzieleFormulieren",
        },
        {
          label: "Beispiele für gut formulierte Feinlernziele",
          view: "LernzielBeispiele",
        },
        {
          label: "Dynamische Lehrplan-Datenbank Sachsen",
          view: "DynamischeLehrplanDatenbank",
        },
      ],
    },
  ];

  // Aktualisieren der aktiven Ansicht
  useEffect(() => {
    if (
      links.some((link) => link.view === currentView) ||
      links.some((link) =>
        link.subItems?.some((subItem) => subItem.view === currentView)
      )
    ) {
      setActiveView(currentView);
    } else {
      setActiveView("");
    }
  }, [currentView, links]);

  // Rendern der Sidebar
  return (
    <div className="sidebar">
      <ul>
        {links.map((link) => (
          <React.Fragment key={link.view}>
            <li className={activeView === link.view ? "active" : ""}>
              <a
                href="#"
                className={activeView === link.view ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  if (link.view === "Wiki") {
                    setIsWikiExpanded(!isWikiExpanded);
                  } else if (link.view === "about") {
                    setIsAboutExpanded(!isAboutExpanded);
                  } else {
                    setView(link.view);
                    setStartseite(link.view === "home");
                    setActiveView(link.view);
                  }
                }}
              >
                {link.label}
                {(link.view === "Wiki" || link.view === "about") && (
                  <span>
                    {(link.view === "Wiki" && isWikiExpanded) ||
                    (link.view === "about" && isAboutExpanded)
                      ? "▾"
                      : "▸"}
                  </span>
                )}
              </a>
            </li>
            {link.subItems &&
              ((link.view === "Wiki" && isWikiExpanded) ||
                (link.view === "about" && isAboutExpanded)) && (
                <ul>
                  {link.subItems.map((subItem) => (
                    <li
                      key={subItem.view}
                      className={activeView === subItem.view ? "active" : ""}
                    >
                      <a
                        href="#"
                        className={activeView === subItem.view ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setView(subItem.view);
                          setStartseite(false);
                          setActiveView(subItem.view);
                        }}
                      >
                        {subItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
