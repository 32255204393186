import React from "react";

/**
 * Ändert die Nutzenformulierung für einen bestimmten Container.
 * @param {React.ChangeEvent<HTMLSelectElement>} e - Das Event-Objekt des Dropdown-Menüs.
 * @param {number} index - Der Index des zu ändernden Containers.
 * @param {React.Dispatch<React.SetStateAction<Array<any>>>} setContainers - Die Funktion zum Aktualisieren des Container-Arrays.
 */
export const handleNutzenformulierungChange = (e, index, setContainers) => {
  const newNutzenformulierung = e.target.value;
  setContainers((prevContainers) =>
    prevContainers.map((c, i) =>
      i === index ? { ...c, nutzenformulierung: newNutzenformulierung } : c
    )
  );
};

/**
 * Props für die NutzenformulierungDropdown-Komponente.
 * @interface NutzenformulierungDropdownProps
 * @property {string} value - Der aktuelle Wert des Dropdowns.
 * @property {(e: React.ChangeEvent<HTMLSelectElement>) => void} onChange - Die Funktion, die bei Änderung des Wertes aufgerufen wird.
 */
interface NutzenformulierungDropdownProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * Die Dropdown-Komponenten der Nutzenformulierung.
 * @param {NutzenformulierungDropdownProps} props - Die Props für die Komponente.
 * @returns {JSX.Element} Das Dropdown-Menü mit den Optionen für die Nutzenformulierung.
 */
const NutzenformulierungDropdown: React.FC<NutzenformulierungDropdownProps> = ({
  value,
  onChange,
}) => {
  return (
    <select value={value} onChange={onChange}>
      <option value="keine">keine</option>
      <option value=", um">, um</option>
      <option value=", damit">, damit</option>
    </select>
  );
};

export default NutzenformulierungDropdown;
