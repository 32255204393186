import React from "react";

function Lernzielarten() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Lernzielarten</h1>
      <h2 className="wiki-untertitel">Blooms Taxonomie als Grundbaustein</h2>

      <p className="wiki-text">
        In den 1950er Jahren erkannte eine Gruppe von Bildungsexperten unter der
        Leitung des Erziehungswissenschaftlers Benjamin S. Bloom die
        Notwendigkeit, Bildungsziele systematisch zu ordnen, um den Lehr- und
        Lernprozess effektiver zu gestalten.
        <br></br>
        <br></br>
        Aus dieser Überlegung heraus entstand das Werk „Taxonomy of Educational
        Objectives“, ein Klassifikationssystem, das darauf abzielt,
        Bildungsziele präzise zu definieren und in eine logische Reihenfolge zu
        bringen
        <br></br>
        <br></br>
        Blooms Taxonomie unterteilt Lernziele in drei zentrale Bereiche: den
        kognitiven, affektiven und psychomotorischen Bereich. Diese Bereiche
        spiegeln die unterschiedlichen Facetten des Lernens wider, von der
        Entwicklung geistiger Fähigkeiten über die Bildung von Einstellungen und
        Werten bis hin zur Förderung motorischer Fertigkeiten Innerhalb dieser
        Bereiche folgt die Taxonomie einem hierarchischen Ansatz, bei dem
        einfache Fähigkeiten und Kenntnisse als Grundlage für komplexere
        Lernziele dienen.
        <p className="text-quelle">(Bloom et al., 1956, S. 5-7)</p>
      </p>
      <h2 className="wiki-untertitel">
        🧠 kognitive Domäne - nach Anderson & Krathwohl
      </h2>

      <p className="wiki-text">
        In 1990er Jahren überarbeitete Blooms Kollege David Krathwohl, welcher
        bereits Co-Autor im Werk „Taxonomy of Educational Objectives“ war,
        gemeinsam mit dem ehemaligen Schüler von Bloom - Lorin Anderson die
        kognitive Domäne.
        <br></br>
        <br></br>
        <ul>
          <li>
            <strong>Erinnern</strong> (Remember): Informationen abrufen.
          </li>

          <li>
            <strong>Verstehen</strong> (Understand): Erfassen von Bedeutung,
            Übersetzung, Interpretation und Anwendung von Wissen auf neue
            Situationen
          </li>

          <li>
            <strong>Anwenden</strong> (Apply): Handlungsabläufe und gelernte
            Konzepte in neuen Situationen verwenden. bestimmten Situationen.
          </li>

          <li>
            <strong>Analysieren</strong> (Analyze): Informationen in ihre
            Bestandteile zerlegen und Beziehungen verstehen.
          </li>

          <li>
            <strong>Bewerten</strong> (Evaluate): Urteile über den Wert von
            Informationen oder Methoden fällen.
          </li>

          <li>
            <strong>Erschaffen</strong> (Create): aus verschiedenen Elementen
            eine neue Struktur oder Bedeutung schaffen.
          </li>
        </ul>
        <p className="text-quelle">(Anderson & Krathwohl, 2001, S. 69-88)</p>
      </p>

      <h2 className="wiki-untertitel">
        ❤️ affektive Domäne - nach Krathwohl, Bloom & Masia
      </h2>
      <p className="wiki-text">
        Die affektive Domäne umfasst Emotionen, Einstellungen, Interessen,
        Wertschätzungen und moralische Überzeugungen
        <p className="text-quelle">(Bloom et al., 1956, S. 7)</p>
      </p>
      <p className="wiki-text">
        Die Arbeit an diesem Themengebiet führten Krathwohl, Bloom und Masia
        gemeinsam durch und fassten ihre Erkenntnisse im zweiten Handbuch des
        „Taxonomy of Educational Objectives“ zusammen.
        <p className="text-quelle">(Krathwohl et al., 1973, S. 12-13)</p>
      </p>
      <p className="wiki-text">
        <ul>
          <li>
            <strong>Aufnahmebereitschaft</strong> (Receiving): Fähigkeit,
            bestimmte Reize wahrzunehmen und auf diese zu achten.
          </li>

          <li>
            <strong>Reagieren</strong> (Responding): aktive Teilnahme und
            Reaktion auf das, was wahrgenommen wurde.
          </li>

          <li>
            <strong>Wertung</strong> (Valuing): einem bestimmten Objekt,
            Phänomen oder Verhalten, eine persönliche Bedeutung oder Wert
            zuschreiben.
          </li>

          <li>
            <strong>Organisation</strong> (Organization): Wertesystem
            organisieren, Prioritäten setzen und eigene Werte in Beziehung mit
            anderen bringen.
          </li>

          <li>
            <strong>
              Charakterisierung durch einen Wert oder einem Wertkomplex
            </strong>{" "}
            (Characterization by a Value or Value Complex): Werte der Lernenden
            sind bereits vollständig internalisiert und einem Wertesystem
            zugeordnet. Die Werte sind somit integraler Bestandteil der
            Persönlichkeit und bestimmen dadurch konsistent das Verhalten in
            allen Lebensbereichen.
          </li>
        </ul>
        <p className="text-quelle">(Krathwohl et al., 1973, S. 98-166). </p>
      </p>

      <h2 className="wiki-untertitel">
        🏃‍♂️ psychomotorische Domäne - nach Harrow
      </h2>
      <p className="wiki-text">
        Die psychomotorische Domäne umfasst den Bereich von manipulativen und
        motorischen Fähigkeiten. In seinem Werk, dem „Taxonomy of Educational
        Objectives“ fordert Bloom andere pädagogische Fachkräfte auf, eine
        Klassifizierung für diese Domäne vorzunehmen. Bloom begründet diese
        Entscheidung durch seine unzureichende Erfahrung auf diesem Gebiet
        <p className="text-quelle">(Bloom et al., 1956, S. 7-8)</p>
      </p>
      <p className="wiki-text">
        Die Besonderheit in Harrows Ansatz liegt darin, dass dieses Schema
        speziell für Pädagogen entwickelt wurde und es soll sie beim Einordnen,
        der beobachtbaren Bewegungsphänomene in die sechs hierarchischen Ebenen
        des psychomotorischen Bereichs unterstützen.
        <p className="text-quelle">(Harrow, 1972, S. 46)</p>
      </p>
      <p className="wiki-text">
        <ul>
          <li>
            <strong>Reflexbewegungen</strong> (Reflex Movements): sind von Natur
            aus unwillkürlich, bereits zur Geburt funktionell und entwickeln
            sich während des Wachstums.
          </li>

          <li>
            <strong>grundlegende Bewegungsmuster</strong> (Basic-fundamental
            movement): entstehen im ersten Lebensjahr und entwickeln sich in
            einer sehr strukturierten und vorhersagbaren Weise.
          </li>

          <li>
            <strong>Wahrnehmungsfähigkeiten</strong> (Perceptual Abilities):
            kinästhetische, visuelle, auditive, taktile oder koordinative Reize
            werden durch die höheren Gehirnzentren interpretiert und es folgt
            eine Antwort in Form einer Reaktion.
          </li>

          <li>
            <strong>Körperliche Fähigkeiten</strong> (Physical Abilities):
            Ausdauer, Kraft, Stärke und Beweglichkeit der Lernenden.
          </li>

          <li>
            <strong>geschickten Bewegungen</strong> (Skilled Movements):
            fortgeschrittene Bewegungen die erlernt wurden und einen bestimmten
            Grad an Effizienz erreichen. Kategorisierung in Schwierigkeitsgrad
            der Fertigkeit und Grad der Beherrschung.
          </li>

          <li>
            <strong>Nicht-diskursive Kommunikation</strong> (Non-Discursive
            Communication): Ausdruckswert im Fokus. Unterscheidung zwischen
            ausdrucksstarker und Interpretierender Bewegung.
          </li>
        </ul>
        <p className="text-quelle">(Harrow, 1972, S. 46-93)</p>
      </p>
      <h2 className="wiki-literatur">Literatur</h2>
      <p className="wiki-literatur-text">
        Anderson, L. W., & Krathwohl, D. R. (Hrsg.). (2001). A taxonomy for
        learning, teaching, and assessing: A revision of Bloom’s taxonomy of
        educational objectives (Complete ed). Longman.
        <br></br>
        <br></br>
        Bloom, B. S., Engelhart, M. D., Furst, E. J., Hill, W. H., & Krathwohl,
        D. R. (1956). Taxonomy of educational objectives. 1: Cognitive domain.
        Longman.
        <br></br>
        <br></br>
        Harrow, A. J. (1972). A taxonomy of the psychomotor domain: A guide for
        developing behavioral objectives. Longman.
        <br></br>
        <br></br>
        Krathwohl, D. R., Bloom, B. S., & Masia, B. B. (1973). Affective domain
        (1. ed., reprinted). McKay.
      </p>
    </div>
  );
}

export default Lernzielarten;
