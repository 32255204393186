import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useProjectContext } from "./laufendesProjekt.tsx";
import { ViewType } from "../App";
import { FaExclamationTriangle } from "react-icons/fa";

/**
 * @interface EinstellungenProps
 * @description Eigenschaften für die Einstellungen-Komponente
 */
interface EinstellungenProps {
  /** Funktion zum Setzen der aktuellen Ansicht */
  setView: (view: ViewType) => void;
  /** Funktion zum Setzen des Status des Dropdowns */
  setDropdownActive: React.Dispatch<React.SetStateAction<boolean>>;
  /** Funktion zum Auslösen eines Neuladen-Events */
  triggerReload: () => void;
  /** JSON-Daten */
  jsonData: Record<string, any>;
  /** Name des aktuellen Projekts */
  laufendesProjekt: string;
  /** Funktion zum Setzen des aktuellen Projekts */
  setLaufendesProjekt: (projekt: string) => void;
}

/**
 * @function Einstellungen
 * @description Die Hauptkomponente dieser Datei "Einstellungen"
 * @param {EinstellungenProps} props - Die Eigenschaften der Komponente
 * @returns {JSX.Element} Die gerenderte Einstellungen-Komponente
 */
function Einstellungen({
  setView,
  setDropdownActive,
  triggerReload,
  jsonData,
  laufendesProjekt,
  setLaufendesProjekt,
}: EinstellungenProps) {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const confirmationRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const [resetTimer, setResetTimer] = useState(10);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);

  const { setProjektState } = useProjectContext();

  const options = [
    { label: "Neues Projekt anlegen", view: "NewProject" },
    {
      label: "Daten exportieren",
      view: "ExportSettings",
    },
    {
      label: "Daten importieren",
      view: "ImportSettings",
    },
    {
      label: "Auf Werkeinstellungen zurücksetzen",
      view: "ResetSettings",
    },
  ];

  /**
   * @function toggleDropdown
   * @description Schaltet den Status des Dropdowns um
   */
  const toggleDropdown = () => {
    setIsActive(!isActive);
    setDropdownActive(!isActive);
  };

  /**
   * @function handleOptionClick
   * @description händelt die Klicks auf die Dropdown Optionen
   * @param {string} view - Die ausgewählte Ansicht
   */
  const handleOptionClick = (view) => {
    if (view === "NewProject") {
      const dontShowAgain =
        localStorage.getItem("dontShowNewProjectAlert") === "true";
      if (!dontShowAgain) {
        setShowConfirmation(true);
      } else {
        handleConfirm(true);
      }
    } else if (view === "ExportSettings") {
      exportSettings();
    } else if (view === "ImportSettings") {
      handleImportSettings();
    } else if (view === "ResetSettings") {
      handleShowResetConfirmation();
    } else {
      setView(view);
      setIsActive(false);
      setDropdownActive(false);
    }
  };

  /**
   * @function exportSettings
   * @description Exportiert den gesamten local Storage als JSON-Datei
   */
  const exportSettings = () => {
    const settings = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        settings[key] = localStorage.getItem(key);
      }
    }

    const jsonString = JSON.stringify(settings, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Lernziele_Konfiguration.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    setIsActive(false);
    setDropdownActive(false);
  };

  /**
   * @function handleImportSettings
   * @description Öffnet das Modal zum Importieren der Daten
   */
  const handleImportSettings = () => {
    setCurrentView("ImportSettings");
    setIsActive(false);
    setDropdownActive(false);
  };

  /**
   * @function handleFileUpload
   * @description Upload der JSON-Datei durch Auswahl der Datei über Ordnersturktur
   * @param {React.ChangeEvent<HTMLInputElement>} event - Das Datei-Upload-Event
   */
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const settings = JSON.parse(e.target?.result as string);
          importSettings(settings);
        } catch (error) {
          console.error("Fehler beim Parsen der JSON-Datei:", error);
          alert(
            "Fehler beim Importieren der Daten. Bitte überprüfen Sie die Datei."
          );
        }
      };
      reader.readAsText(file);
    }
  };

  /**
   * @function handleDragEnter
   * @description händelt den Bereich für das Drag&Drop, wenn eine Datei darüber gezogen wird. State wird dabei aktualisiert, wenn eine Datei den Bereich betritt
   * @param {React.DragEvent<HTMLDivElement>} event - Das Drag-Enter-Event
   */
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  /**
   * @function handleDragOver
   * @description händelt den Bereich für das Drag&Drop, wenn eine Datei darüber gezogen wird. State wird nicht aktualisiert, da dies für die Gestalltung wichtig ist
   * @param {React.DragEvent<HTMLDivElement>} event - Das Drag-Over-Event
   */
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * @function handleDragLeave
   * @description Ermittelt die Grenzen des Drag&Drop Bereiches und händelt dann das Drag-Leave-Event, wenn die Datei den Bereich verlässt
   * @param {React.DragEvent<HTMLDivElement>} event - Das Drag-Leave-Event
   */
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX;
    const y = event.clientY;
    if (
      x <= rect.left ||
      x >= rect.right ||
      y <= rect.top ||
      y >= rect.bottom
    ) {
      setIsDragOver(false);
    }
  };

  /**
   * @function handleDrop
   * @description händelt das Verhalten, wenn eine Datei über den Drag&Drop Bereich gezogen und losgelassen wird
   * @param {React.DragEvent<HTMLDivElement>} event - Das Drop-Event
   */
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const settings = JSON.parse(e.target?.result as string);
          importSettings(settings);
        } catch (error) {
          console.error("Fehler beim Parsen der JSON-Datei:", error);
          alert(
            "Fehler beim Importieren der Daten. Bitte überprüfen Sie die Datei."
          );
        }
      };
      reader.readAsText(file);
    }
  };

  /**
   * @function importSettings
   * @description Es wird die JSON Datei gelesen und dessen inhalt im local Storage gespeichert
   * @param {any} settings - Die zu importierenden Einstellungen
   */
  const importSettings = (settings: any) => {
    localStorage.clear();

    Object.entries(settings).forEach(([key, value]) => {
      if (value !== null) {
        localStorage.setItem(key, value as string);
      }
    });

    if (settings.laufendesProjekt) {
      setLaufendesProjekt(settings.laufendesProjekt);
    }

    const isDarkMode = settings.darkMode === "true";
    document.body.classList.toggle("dark-mode", isDarkMode);
    document.body.style.backgroundColor = isDarkMode ? "#222831" : "#EEEEEE";

    alert("Daten wurden erfolgreich importiert und überschrieben");

    setCurrentView("");
    triggerReload();

    window.location.reload();
  };

  /**
   * @function handleOverlayClick
   * @description händelt die Klicks auf dem Modal
   * @param {React.MouseEvent<HTMLDivElement>} event - Das Klick-Event
   */
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setCurrentView("");
    }
  };

  /**
   * @function handleConfirm
   * @description Für das Bestätigen zum erstellen eines neuen Projekts
   * @param {boolean} skipConfirmation - Flag zum Überspringen der Bestätigung
   */
  const handleConfirm = (skipConfirmation = false) => {
    if (dontShowAgain || skipConfirmation) {
      localStorage.setItem("dontShowNewProjectAlert", "true");
    }

    const currentProject = localStorage.getItem("laufendesProjekt");

    if (currentProject) {
      localStorage.removeItem(currentProject);
    }

    setShowConfirmation(false);
    setIsActive(false);
    setDropdownActive(false);
    window.location.reload();
  };

  /**
   * @function handleConfirmClick
   * @description händelt den Klick auf den "bestätigen" Button
   */
  const handleConfirmClick = () => {
    handleConfirm();
  };

  /**
   * @function handleCancel
   * @description Bricht den Vorgang ab, wenn man auf "abbrechen" klickt
   */
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  /**
   * @function handleResetConfirm
   * @description händelt das Bestätigen zum Zurücksetzen auf Werkeinstellungen und den Timer
   */
  const handleResetConfirm = () => {
    localStorage.clear();
    setShowResetConfirmation(false);
    setIsActive(false);
    setDropdownActive(false);
    window.location.reload();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showResetConfirmation && resetTimer > 0) {
      timer = setInterval(() => {
        setResetTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (resetTimer === 0) {
      setIsResetButtonDisabled(false);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showResetConfirmation, resetTimer]);

  /**
   * @function handleShowResetConfirmation
   * @description Ziegt den Dialog an und startet den Timer
   */
  const handleShowResetConfirmation = () => {
    setShowResetConfirmation(true);
    setResetTimer(10);
    setIsResetButtonDisabled(true);
  };

  /**
   * @function handleResetCancel
   * @description Vorgang zum Abbrechen des Zurücksetzen auf Werkeinstellungen
   */
  const handleResetCancel = () => {
    setShowResetConfirmation(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
        setDropdownActive(false);
      }
      if (
        confirmationRef.current &&
        !confirmationRef.current.contains(event.target as Node)
      ) {
        setShowConfirmation(false);
        setShowResetConfirmation(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, confirmationRef]);

  return (
    <div className="dropdown-wiki" ref={dropdownRef}>
      <div className="dropdown-wiki-button" onClick={toggleDropdown}>
        <span>&#x2630;</span>
      </div>
      {isActive && (
        <div className="dropdown-wiki-Inhalt">
          {options.map((option) => (
            <div
              key={option.label}
              onClick={() => handleOptionClick(option.view)}
              className="dropdown-wiki-items"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {showConfirmation && (
        <div className="universal-alert-overlay" onClick={handleOverlayClick}>
          <div className="universal-alert" ref={confirmationRef}>
            <p>
              <strong>
                Durch das Anlegen eines neuen Projektes gehen alle Änderungen
                verloren. Sind Sie damit einverstanden?
              </strong>
            </p>
            <div className="checkbox-container">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={dontShowAgain}
                  onChange={(e) => setDontShowAgain(e.target.checked)}
                />
                <span className="checkbox-label">
                  Diese Meldung nicht mehr anzeigen
                </span>
              </label>
            </div>
            <div className="universal-button-container">
              <button onClick={handleConfirmClick} className="universal-button">
                Bestätigen
              </button>
              <button onClick={handleCancel} className="universal-button">
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      )}
      {showResetConfirmation && (
        <div className="universal-alert-overlay" onClick={handleOverlayClick}>
          <div className="universal-alert" ref={confirmationRef}>
            <div className="warning-icon">
              <FaExclamationTriangle />
            </div>
            <p>
              <strong>
                Wenn Sie den Lernzielformulator auf die Werkeinstellung
                zurücksetzen, dann gehen alle ihre Änderungen, wie
                beispielsweise von ihnen hinzugefügte oder bearbeitete
                Zielgruppen, Kompetenzverben und Tätigkeitsverben.
              </strong>
            </p>
            <p>Sind Sie sicher und wollen fortfahren?</p>
            <div className="universal-button-container">
              <button
                onClick={handleResetConfirm}
                className={`universal-button ${
                  isResetButtonDisabled ? "disabled" : ""
                }`}
                disabled={isResetButtonDisabled}
              >
                Bestätigen {isResetButtonDisabled ? `(${resetTimer})` : ""}
              </button>
              <button onClick={handleResetCancel} className="universal-button">
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "ImportSettings" && (
        <div className="universal-alert-overlay" onClick={handleOverlayClick}>
          <div className="import-dialog">
            <h2 className="import-dialog-title">Einstellungen importieren</h2>
            <div
              className={`drop-area ${isDragOver ? "drag-over" : ""}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p>JSON-Datei hier ablegen</p>
              <span className="upload-icon">&#x1F4E4;</span>
            </div>
            <button
              className="open-button"
              onClick={() => fileInputRef.current?.click()}
            >
              <span className="folder-icon">📁</span> Datei auswählen
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept=".json"
            />
          </div>
        </div>
      )}
    </div>
  );
}

Einstellungen.propTypes = {
  setView: PropTypes.func.isRequired,
  setDropdownActive: PropTypes.func.isRequired,
  triggerReload: PropTypes.func.isRequired,
  jsonData: PropTypes.object.isRequired,
  laufendesProjekt: PropTypes.string.isRequired,
  setLaufendesProjekt: PropTypes.func.isRequired,
};

export default Einstellungen;
