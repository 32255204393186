import React from "react";

function Impressum() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Impressum</h1>
      <h2 className="wiki-untertitel">Anbieterkennzeichnung</h2>
      <p className="wiki-text">
        <strong>Technische Universität Dresden</strong>
        <br></br>
        <strong>01062 Dresden</strong>
        <br></br>
        <br></br>Tel.: +49 (0) 351 463-0
        <br></br>
        Fax: +49 (0) 351 463-37284
        <br></br>
        <br></br>
        Der Internetauftritt der TU Dresden setzt sich aus den verschiedenen
        Angeboten der universitären Einrichtungen zusammen. Hierzu gehören unter
        anderem die einzelnen Fakultäten und Zentren, Zentraleinrichtungen und
        Verwaltungseinrichtungen. Die redaktionelle Verantwortlichkeit liegt
        dabei bei den Institutionen oder Personen, die jeweilige Seite erstellt
        haben und als Autorinnen und Autoren am Ende der Seite benannt werden.
        Sollten Sie Fragen zu den Inhalten auf den betreffenden Seiten haben,
        wenden Sie sich bitte an die jeweils dort angegebenen Ersteller.
        <br></br>
        <br></br>
        Die Technische Universität Dresden ist nach § 2 Abs. 1 Sächsisches
        Hochschulfreiheitsgesetz eine Körperschaft des öffentlichen Rechts. Sie
        wird nach § 82 Abs. 1 S. 2 Sächsisches Hochschulgesetz durch die
        amtierende Rektorin Prof. Dr. Ursula M. Staudinger vertreten.
      </p>
    </div>
  );
}

export default Impressum;
