import React from "react";

function Entwicklung() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Der Lernzielformulator</h1>
      <img
        src={`/assets/David_Root.png`}
        alt="Porträt - David Root"
        className="wiki-foto"
      />
      <p className="img-quelle">(David Root 2024)</p>
      <p className="wiki-text">
        Die Erfahrung zeigt, dass besonders im frühen Stadium der
        Lehrer:innenausbildung immer wieder Probleme und Schwierigkeiten beim
        Erstellen von Lernzielen auftreten.Es werden die Regeln zum Formulieren
        operationalisierbarer Lernziele missachtet und so beispielsweise
        Operatoren nicht sinnstiftend gewählt, weggelassen oder sogar Begriffe
        wie „können“ und „wissen“ verwendet.
        <br></br>
        <br></br>
        Um dieser Problematik entgegenzutreten, ergab sich im Rahmen einer{" "}
        <strong>Staatsexamensarbeit</strong> aus fachinformatischer Sicht die
        Aufgabe, ein softwaregestütztes Unterstützungssystem zur Formulierung
        operationalisierter Lernziele zu entwickeln.
        <br></br>
        <br></br>
        Die unter dem Codenamen „<strong>Lernzielformulator</strong>“
        beschriebene Anwendung soll angehende sowie aktive Lehrkräften das
        Erstellen von Lernzielen erleichtern und dabei helfen, potenzielle
        Fehler zu vermeiden.
      </p>
      <img
        src="/assets/by-nc-sa.eu.svg"
        alt="CC BY-SA 4.0"
        className="Lizenz"
      />
    </div>
  );
}

export default Entwicklung;
