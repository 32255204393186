import React from "react";

function LernzieltaxonomieSaechsischerLehrplan() {
  return (
    <div className="wiki-container">
      <h1 className="wiki-titel">Lernzietaxonomie - Sächsischer Lehrplan</h1>
      <h2 className="wiki-untertitel">Einblick gewinnen</h2>
      <p className="wiki-text">
        Begegnung mit einem Gegenstandsbereich/Wirklichkeitsbereich oder mit
        Lern- und Arbeitstechniken oder Fachmethoden als{" "}
        <strong>grundlegende Orientierung</strong>, ohne tiefere Reflexion
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Kennen</h2>
      <p className="wiki-text">
        über <strong>Kenntnisse und Erfahrungen</strong> zu Sachverhalten und
        Zusammenhängen, zu Lern- und Arbeitstechniken oder Fachmethoden sowie zu
        typischen Anwendungsmustern{" "}
        <strong>aus einem begrenzten Gebiet im gelernten Kontext</strong>
        verfügen
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Übertragen</h2>
      <p className="wiki-text">
        <strong>Kenntnisse und Erfahrungen</strong> zu Sachverhalten und
        Zusammenhängen, im Umgang mit Lern- und Arbeitstechniken oder
        Fachmethoden in <strong>vergleichbaren Kontexten</strong> verwenden
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Beherrschen</h2>
      <p className="wiki-text">
        <strong>Handlungs- und Verfahrensweisen routinemäßig</strong> gebrauchen
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Anwenden</h2>
      <p className="wiki-text">
        <strong>Kenntnisse und Erfahrungen</strong>zu Sachverhalten und
        Zusammenhängen, im Umgang mit Lern- und Arbeitstechniken oder
        Fachmethoden durch Abstraktion und Transfer{" "}
        <strong>in unbekannten Kontexten</strong>
        verwenden
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Beurteilen/ Sich positionieren</h2>
      <p className="wiki-text">
        <strong>begründete Sach- und/oder Werturteile</strong>entwickeln und
        darstellen, <strong> Sach- und/oder Wertvorstellungen</strong>
        in Toleranz gegenüber anderen annehmen oder ablehnen, vertreten,
        kritisch reflektieren und ggf. revidieren
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>

      <h2 className="wiki-untertitel">Gestalten/ Problemlösen</h2>
      <p className="wiki-text">
        <strong>Handlungen/Aufgaben</strong> auf der Grundlage von Wissen zu
        komplexen Sachverhalten und Zusammenhängen, Lern- und Arbeitstechniken,
        geeigneten Fachmethoden sowie begründeten Sach- und/oder Werturteilen{" "}
        <strong>selbstständig planen, durchführen, kontrollieren</strong>
        <strong>sowie zu neuen Deutungen und Folgerungen</strong> gelangen
        <p className="text-quelle">
          (Sächsisches Staatsministerium für Kultus, 2022, S. V)
        </p>
      </p>
      <h2 className="wiki-literatur">Literatur</h2>
      <p className="wiki-literatur-text">
        Sächsisches Staatsministerium für Kultus. (2022). Lehrplan Oberschule
        Informatik. https://www.schulportal.sachsen.de/lplandb/
      </p>
    </div>
  );
}

export default LernzieltaxonomieSaechsischerLehrplan;
