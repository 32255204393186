import React, { createContext, useState, useContext, ReactNode } from "react";
import { ViewType } from "../App";
import { IoArrowRedoCircle } from "react-icons/io5";

/**
 * @interface ProjectContextType
 * @description Definiert die Struktur des Projektkontexts
 */
export interface ProjectContextType {
  /** Aktuelles Projekt (Lernzielformulierung) */
  laufendesProjekt: string;
  /** Funktion zum Setzen des laufenden Projekts */
  setLaufendesProjekt: (projekt: string) => void;
  /** Zustände aller Projekte */
  projektStates: Record<string, Record<string, any>>;
  /** Funktion zum Setzen des Zustands eines Projekts */
  setProjektState: (projekt: string, state: Record<string, any>) => void;
}

/** @type {React.Context<ProjectContextType | undefined>} Projektkontext */
const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

/**
 * @component ProjectProvider
 * @description Provider-Komponente für den Projektkontext
 * @param {Object} props - Komponentenprops
 * @param {ReactNode} props.children - Kind-Elemente
 */
export const ProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  /** @type {[string, React.Dispatch<React.SetStateAction<string>>]} State-Hook für das laufende Projekt */
  const [laufendesProjekt, setLaufendesProjektState] = useState<string>(() => {
    return localStorage.getItem("laufendesProjekt") || "";
  });

  /** @type {[Record<string, Record<string, any>>, React.Dispatch<React.SetStateAction<Record<string, Record<string, any>>>>]} State-Hook für die Projektzustände */
  const [projektStates, setProjektStates] = useState<
    Record<string, Record<string, any>>
  >({});

  /**
   * @function setLaufendesProjekt
   * @description Setzt das laufende Projekt und speichert es im localStorage
   * @param {string} projekt - Name des zu setzenden Projekts
   */
  const setLaufendesProjekt = (projekt: string) => {
    setLaufendesProjektState(projekt);
    localStorage.setItem("laufendesProjekt", projekt);
  };

  /**
   * @function setProjektState
   * @description Aktualisiert den Zustand eines Projekts und speichert ihn im localStorage
   * @param {string} projekt - Name des Projekts
   * @param {Record<string, any>} state - Neuer Zustand des Projekts
   */
  const setProjektState = (projekt: string, state: Record<string, any>) => {
    setProjektStates((prevStates) => ({
      ...prevStates,
      [projekt]: state,
    }));
    localStorage.setItem(projekt, JSON.stringify(state));
  };

  return (
    <ProjectContext.Provider
      value={{
        laufendesProjekt,
        setLaufendesProjekt,
        projektStates,
        setProjektState,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

/**
 * @function useProjectContext
 * @description Hook für den Zugriff auf den Projektkontext
 * @returns {ProjectContextType} Projektkontext
 * @throws {Error} Fehler, wenn außerhalb eines ProjectProviders verwendet
 */
export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProjectContext must be used within a ProjectProvider");
  }
  return context;
};

/**
 * @component LaufendesProjektLink
 * @description Komponente für den Link zum laufenden Projekt
 * @param {Object} props - Komponentenprops
 * @param {(view: ViewType) => void} props.setView - Funktion zum Setzen der Ansicht
 */
const LaufendesProjektLink: React.FC<{ setView: (view: ViewType) => void }> = ({
  setView,
}) => {
  const { laufendesProjekt } = useProjectContext();

  return (
    <a
      href="/laufendes-projekt"
      className="laufendes-projekt"
      onClick={(e) => {
        e.preventDefault();
        setView(laufendesProjekt as ViewType);
      }}
    >
      <IoArrowRedoCircle className="animated-icon" />
    </a>
  );
};

export default LaufendesProjektLink;
